import React, { useState, useEffect } from "react";
import { Stack, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const DateTimer = ({ setValueTemp, initialDate, label }) => {
  const [value, setValue] = useState(initialDate);
  useEffect(() => {
    setValueTemp(initialDate);
  }, []);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Stack spacing={3}>
        <DatePicker
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
            setValueTemp(newValue);
          }}
          inputFormat="YYYY-MM-DD"
          label={label}
          renderInput={(params) => (
            <TextField
              label={label}
              name="fecha"
              size="small"
              // variant="outlined"
              {...params}
            />
          )}
        />
      </Stack>
    </LocalizationProvider>
  );
};

export default DateTimer;
