import client from "libs/client";

const getUsers = async () => {
  let response = await client("POST", {}, "/admin/users/view");
  return response;
};
const changeStatusUser = async (data) => {
  let response = await client("POST", data, "/admin/users/estado");
  return response;
};
const createUser = async (data) => {
  let response = await client("POST", data, "/admin/users/create");
  return response;
};
const changePass = async (data) => {
  let response = await client("POST", data, "/admin/users/passwd");
  return response;
};
const userService = {
  getUsers,
  changeStatusUser,
  createUser,
  changePass
};

export default userService;
