import React, { useState } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { config } from "utils/config";
import { useSelector, useDispatch } from "react-redux";
import { asignarPruebas } from "libs/redux/slices/testSlice";

const divPaddingBottom = {
  paddingBottom: "0.5em",
  paddingTop: "1.2em",
  paddingRight: "1.5em",
};

const cardImage = {
  width: "auto",
  height: "auto",
  maxHeight: 280,
  maxWidth: 216,
  padding: "0.5em",
};

const RenderRadio = ({ opcion, handleClick }) => {
  return (
    <FormControlLabel
      control={<Radio />}
      id={opcion.codOpcion}
      value={String(opcion.codOpcion)}
      // checked={value === String(opcion.codOpcion)}
      onClick={handleClick}
      label={opcion.recurso}
    />
  );
}

const RenderOpcion = (props) => {
  const { handleRadioChange, codPregunta, opciones } = props;
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const server = config.API_RESOURCE_URL;
  async function handleClick(event) {
    if (event.target.value === value) {
      setValue("");
    } else {
      setValue(event.target.value);
    }
    const response = await handleRadioChange(event);
    if (response !== null) {
      dispatch(asignarPruebas({
        respuestas: response.respuestas,
        respuestasSeleccionadas: response.respuestasSeleccionadas
      }));
    }
  }
  return (
    <>
      <RadioGroup
        row
        aria-label="opciones"
        name={String(codPregunta)}
        id={codPregunta + "-radio-group"}
        // onChange={handleClick}
        value={value || ""}
      >
        {opciones.map((opcion, index) => (
          <div key={index} style={divPaddingBottom}>
            {opcion.tipo === "texto" ? (
              <RenderRadio opcion={opcion} handleClick={handleClick} />
            ) : opcion.tipo === "imagen" ? (
              <div>
                <FormControlLabel
                  id={opcion.codOpcion}
                  value={String(opcion.codOpcion)}
                  onClick={(e) => handleClick(e)}
                  control={<Radio />}
                  label={
                    // eslint-disable-next-line jsx-a11y/alt-text
                    <img
                      style={cardImage}
                      component="img"
                      src={server + opcion.recurso}
                    />
                  }
                ></FormControlLabel>
              </div>
            ) : (
              "neutral"
            )}
          </div>
        ))}
      </RadioGroup>
    </>
  );
};
export default RenderOpcion;
