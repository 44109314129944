import React from "react";
import { Typography, CardMedia, Grid } from "@mui/material";
import { config } from "utils/config";
import AudioPlayer from "./Opciones/AudioPlayer";
import AudioText from "components/AudioText";

const cardImage = {
  width: "auto",
  height: "auto",
  maxHeight: "50%",
  maxWidth: "50%",
  padding: "0.5em",
};

const RenderPregunta = (props) => {
  const server = config.API_RESOURCE_URL;
  const consec = !props.otros.ocultarConsec ? `${props.index + 1}. ` : "";
  const descripcion = !props.otros.ocultarDescripcion
    ? props.pregunta.recurso
    : "";
  const ocultarTextoAudio = props.otros.ocultarTextoAudio;
  switch (props.pregunta.tipo) {
    case "imagen":
      return (
        <>
          {!props.filter && (
            <Typography variant="h6" gutterBottom sx={{ color: "red" }}>
              {consec}
            </Typography>
          )}
          <CardMedia
            style={cardImage}
            component="img"
            src={server + props.pregunta.recurso}
          />
          <NotaAdicional notaAdicional={props.pregunta.notaAdicional} />
        </>
      );
    case "video":
      return (
        <>
          {!props.filter && (
            <Typography variant="h6" gutterBottom>
              {consec}
            </Typography>
          )}
          <CardMedia
            style={cardImage}
            component="video"
            src={server + props.pregunta.recurso}
            controls
          />
          <NotaAdicional notaAdicional={props.pregunta.notaAdicional} />
        </>
      );
    case "audio":
      return (
        <>
          {!props.filter && (
            <Typography variant="h6" gutterBottom>
              {consec}
            </Typography>
          )}
          <AudioPlayer
            url={server + props.pregunta.recurso}
            validarEjecuciones={true}
            recurso={props.pregunta.recurso}
          />
          <NotaAdicional notaAdicional={props.pregunta.notaAdicional} />
        </>
      );
    case "texto":
      return (
        <>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              bgcolor: "background.paper",
              borderRadius: 1,
              //mt: index === 0 ? "12em" : null,
              //mt: index === 0 ? "8em" : null,
            }}
          >
            <Grid item>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ color: "primary.dark", fontSize: "18px" }}
              >
                {consec + descripcion}
              </Typography>
            </Grid>
            {!ocultarTextoAudio && (
              <Grid item sx={{ pl: "0.5em" }}>
                <AudioText text={descripcion} />
              </Grid>
            )}
          </Grid>
          <NotaAdicional notaAdicional={props.pregunta.notaAdicional} />
        </>
      );
    default:
      return "neutral";
  }
};

const NotaAdicional = (notaAdicional) => {
  return (
    <>
      {!notaAdicional && (
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{ color: "primary.main" }}
        >
          {notaAdicional}
        </Typography>
      )}
    </>
  );
};
export default RenderPregunta;
