import axios from "axios";
import { config } from "../utils/config";
import Swal from "sweetalert2";
import cache from "data/cache";

const client = (method, data, route, resource) =>
  new axios({
    method: method,
    url: (!resource ? config.API_URL : config.API_RESOURCE_URL) + route,
    data: data,
    headers: {
      Authorization: `${cache.getCache("appToken")}`,
      "Content-Type": "application/json",
    },
    //cancelToken: source.token
  }).catch((error) => {
    if (error.response.status === 401) {
      if (cache.getCache("appToken") != null) {
        Swal.fire({
          icon: "info",
          title: "!Su sesión ha expirado!",
          text: "Por favor inicie sesión nuevamente",
          padding: "1rem",
        }).then(() => {
          window.location = "/login";
        });
      } else {
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  });
//source.cancel();

export default client;
