import React from "react";
import { Select } from "@mui/material";
import { styled } from "@mui/material/styles";

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      //width: 250,
    },
  },
};

const SelectStyle = styled(Select)(({ theme }) => ({
  boxShadow: "none",
  textTransform: "none",
  borderRadius: "0.5em",
  //backgroundColor: theme.palette.secondary.light,
  borderColor: theme.palette.secondary.light,
}));

function SelectCustom(props) {
  return <SelectStyle MenuProps={MenuProps} {...props} />;
}

export default SelectCustom;
