import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import cache from "data/cache";
import Home from "layouts/Home";
import Dashboard from "layouts/Dashboard";
import PageError from "pages/PageError";

import Login from "modules/auth/Login/Login";
import AssignTest from "modules/pruebas/AssignTest/layouts/AssingTest";
import InactiveSolveTest from "modules/pruebas/InactiveSolveTest/layouts/InactiveSolveTest";
import ResultTest from "modules/pruebas/ResultTest/layouts/ResultTest";
import TestList from "modules/pruebas/Test/layouts/TestList";
import SolveTest from "modules/pruebas/Test/layouts/SolveTest/SolveTest";
import AssignSurvey from "modules/encuestas/AssignSurvey/layouts/AssignSurvey";
import SolveTestOne from "modules/pruebas/Test/layouts/SolveTestOne";
import UpdateEstudiante from "modules/estudiantes/updateEstudiante/layouts/UpdateEstudiante";
import RegistroEstudiante from "modules/estudiantes/registroEstudiante/layouts/RegistroEstudiante";
import ReportTest from "modules/pruebas/ReportTest/layouts/ReportTest";
import TableClickeable from "modules/pruebas/Test/components/Opciones/CheckBoxImg";
import ListUsers from "modules/params/usuarios/layouts/ListUsers";
import CreateUsers from "modules/params/usuarios/layouts/CreateUsers";
import InfoUser from "modules/auth/InfoUser/Layouts/InfoUser";
import RecursosTest from "modules/pruebas/RecursosTest/layouts/RecursosTest";

/*const routes = [
  {
    path: "/test",
    element: <Test />
  },
  {
    path: "/dashboard",
    element: <Dashboard />
  }
]*/

const RoutesApi = () => (
  <Router>
    <ProtectedRoute />
  </Router>
);

const ProtectedRoute = () => {
  const navigate = useNavigate();
  useEffect(() => {
    let token = cache.getCache("appToken");
    if (token == null) {
      //navigate("/login");
    }
  }, [navigate]);
  return (
    <Routes>
      <Route path="*" element={<PageError />} />
      <Route index element={<Home />} />
      <Route path="login" element={<Login />} />
      {/*<Route path="dashboard/test2/:idPrueba" element={<Test/>} />*/}
      <Route path="dashboard" element={<Dashboard />}>
        <Route path="list-test" element={<TestList />} />
        <Route path="assign-test" element={<AssignTest />} />
        <Route path="inactive-test" element={<InactiveSolveTest />} />
        <Route path="result-test" element={<ResultTest />} />
        <Route path="report-test" element={<ReportTest />} />
        <Route path="solve1" element={<SolveTestOne />} />
        <Route path="recursos-test" element={<RecursosTest />} />
        {/* Encuestas */}
        <Route path="assign-survey" element={<AssignSurvey />} />
        {/*<Route path="" element={<TestList />} /> debe ir el home-dashboard aqui*/}
        <Route
          path="estudiantes-registrar-datos"
          element={<RegistroEstudiante />}
        />
        <Route
          path="estudiantes-actualizar-datos"
          element={<UpdateEstudiante />}
        />
        <Route path="users" element={<ListUsers />} />
        <Route path="create-users" element={<CreateUsers />} />
        <Route path="user-info" element={<InfoUser />} />
      </Route>
      <Route path="dashboard/test/:idPrueba" element={<SolveTest />} />
      <Route
        path="dashboard/test/:idPrueba/:fechaEvaluacion"
        element={<SolveTest />}
      />
      <Route path="clickeable" element={<TableClickeable />} />

      {/*routes.map((route, i) =>
        <Route
          key={i}
          path={route.path}
          element={route.element} />
      )*/}
    </Routes>
  );
};

export default RoutesApi;
