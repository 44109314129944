import React, { useState } from "react";
import {
  Avatar,
  CssBaseline,
  Paper,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import imagenAV from "assets/niños_colegio.jpeg";
import Loading from "components/Loading";
import LoginForm from "./LoginForm";

const Login = () => {
  const [openLoading, setOpenLoading] = useState(false);
  return (
    <>
      <Loading openLoading={openLoading} />
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          sm={false}
          md={8}
          sx={{
            backgroundImage: `url(${imagenAV})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
            backgroundPosition: "center",
          }}
        />
        <Grid item sm={12} md={4} component={Paper} elevation={4} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 4, bgcolor: "secondary.dark" }}>
              <LockOpenIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Iniciar Sesión
            </Typography>
            <LoginForm setOpenLoading={setOpenLoading} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
