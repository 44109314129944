import { createTheme } from '@mui/material/styles';

//Gama de color: https://colorhunt.co/palette/fefbf6a6d1e67f52833d3c42
const theme = createTheme({
  palette: {
    background: {
      default: "#DCEAFF", // Color de fondo por defecto
    },
    primary: {
      light: '#6797df',
      main: '#427ED7',
      dark: '#2e5896',
      contrastText: '#ffffff',      
    },
    secondary: {
      light: '#d2d2d2',
      main: '#C7C7C7',
      dark: '#8b8b8b',
      contrastText: '#000000',
    }
  },
})

export default theme;