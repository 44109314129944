import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  FormControl,
  RadioGroup,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  CardMedia,
  Slide,
  TextField,
  Alert,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { styled, useTheme } from "@mui/material/styles";
import { useParams, useNavigate } from "react-router-dom";
import { showAlertSuccess, showAlertError } from "utils/alert";
import RenderPregunta from "./../components/RenderPregunta";
import RenderOpcion from "./../components/RenderOpcion";
import AudioRecorder from "./../components/Opciones/AudioRecorder";
import Instruccion from "./../components/Instruccion";
import Loading from "components/Loading";
import { config } from "utils/config";
import testService from "services/test.services";
import Timer from "./Preguntas/Timer";
import PizarraClickeable from "./Opciones/CheckBoxImg";
import Pizarra from "./Opciones/Pizarra";

const DetailTest = ({
  respPrueba,
  alertasControlRespuestas,
  handleRadioChange,
  handleTextAreaChange,
  timeStartPrueba,
  seconds,
  updateRespuestasSeleccionadas,
  updateSeguimiento,
  activeStep,
  filter,
  //Question
  secondsQuestion,
  setSecondsQuestion,
  starQuestion,
  handleNext,
  testFinalizado,
  lastQuestion,
  cerrarComponentes
}) => {
  function filterArray(index) {
    if (!filter) {
      return true;
    } else {
      return index === activeStep;
    }
  }
  return (    
    <>
      {respPrueba.preguntas.length > 0 &&
        respPrueba.preguntas
          .filter((item, index) => filterArray(index))
          .map((pregunta, index) => (
            <Grid
              key={index}
              container
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                bgcolor: "background.paper",
                borderRadius: 1,
                //mt: index === 0 ? "12em" : null,
                //mt: index === 0 ? "8em" : null,
              }}
            >
              {/*<Grid item xs={2} />*/}
              <Grid
                key={pregunta.codPregunta}
                item
                xs={12}
                sx={{ pl: "3em", pt: "1em", pr: "3em" }}
              >
                {/* Intervalo de tiempo */}
                {secondsQuestion && (
                  <Grid
                    key={pregunta.codPregunta}
                    item
                    xs={12}
                  //sx={{ padding: "1em" }}
                  >
                    <Timer
                      seconds={secondsQuestion}
                      setSeconds={setSecondsQuestion}
                      starQuestion={starQuestion}
                      handleNext={handleNext}
                      testFinalizado={testFinalizado}
                      lastQuestion={lastQuestion}
                    />
                  </Grid>
                )}
                {/*<FormControl component="fieldset" style={{ width: "100%" }}>*/}
                {"seccion" in pregunta && pregunta.seccion !== "" && (
                  <div>
                    <div
                      style={{
                        backgroundColor: "#add8e6",
                      }}
                    >
                      <Typography variant="h5" gutterBottom align="center">
                        {pregunta.seccion}
                      </Typography>
                    </div>
                    <br />
                  </div>
                )}

                <div
                  style={{
                    borderRadius:
                      pregunta.codPregunta in alertasControlRespuestas &&
                        alertasControlRespuestas[pregunta.codPregunta] !== 0
                        ? "0.5em"
                        : "",
                    padding:
                      pregunta.codPregunta in alertasControlRespuestas &&
                        alertasControlRespuestas[pregunta.codPregunta] !== 0
                        ? "1em"
                        : "",
                    border:
                      pregunta.codPregunta in alertasControlRespuestas &&
                        alertasControlRespuestas[pregunta.codPregunta] !== 0
                        ? "solid #d32f2f"
                        : "",
                  }}
                >
                  <RenderPregunta
                    pregunta={pregunta.pregunta}
                    otros={pregunta.otros}
                    index={index}
                    filter={filter}
                  />
                  {pregunta.codPregunta in alertasControlRespuestas &&
                    alertasControlRespuestas[pregunta.codPregunta] !== 0 && (
                      <Alert variant="outlined" severity="error">
                        {alertasControlRespuestas[pregunta.codPregunta]}
                      </Alert>
                    )}
                  {pregunta.opciones.length > 1 &&
                    pregunta.otros.opcion === "pizarra_seleccionable" ? (
                    <PizarraClickeable
                      timeStartPrueba={timeStartPrueba}
                      tiempoLimite={respPrueba.tiempoLimite}
                      seconds={seconds}
                      pregunta={pregunta.codPregunta}
                      //opcion={opcion.codOpcion}
                      opciones={pregunta.opciones}
                      updateRespuestasSeleccionadas={
                        updateRespuestasSeleccionadas
                      }
                      updateSeguimiento={updateSeguimiento}
                      anchoPizarra={pregunta.otros.anchoPizarra}
                      columnItemSize={pregunta.otros.columnItemSize}
                    //recurso={opcion.texto}
                    />
                  ) : pregunta.opciones.length > 1 ? (
                    <RenderOpcion
                      codPregunta={pregunta.codPregunta}
                      opciones={pregunta.opciones}
                      handleRadioChange={handleRadioChange}
                    />
                  ) : (
                    pregunta.opciones.map((opcion, index) =>
                      opcion.tipo === "input_texto_area" ? (
                        <div key={String(opcion.codOpcion)}>
                          <TextField
                            multiline
                            rows={4}
                            id={String(opcion.codOpcion)}
                            name={String(pregunta.codPregunta)}
                            label={opcion.recurso}
                            style={{ width: "100%" }}
                            onChange={handleTextAreaChange(opcion.tipo)}
                          />
                        </div>
                      ) : opcion.tipo === "input_texto" ? (
                        <div key={String(opcion.codOpcion)}>
                          <TextField
                            //multiline
                            rows={4}
                            id={String(opcion.codOpcion)}
                            name={String(pregunta.codPregunta)}
                            label={opcion.recurso}
                            style={{ width: "100%" }}
                            onChange={handleTextAreaChange(opcion.tipo)}
                          />
                        </div>
                      ) : opcion.tipo === "input_numerico" ? (
                        <div key={String(opcion.codOpcion)}>
                          <TextField
                            //multiline
                            rows={4}
                            type="number"
                            id={String(opcion.codOpcion)}
                            name={String(pregunta.codPregunta)}
                            label={opcion.recurso}
                            style={{ width: "100%" }}
                            onChange={handleTextAreaChange(opcion.tipo, pregunta.otros.grupo_item)}
                            InputProps={{
                              inputProps: { min: 0 }
                            }}
                            onKeyPress={(event) => {
                              if (event?.key === '-' || event?.key === '+' || event?.key === '.') {
                                event.preventDefault();
                              }
                            }}
                          />
                        </div>
                      ) : opcion.tipo === "audio" ? (
                        <div key={String(opcion.codOpcion)}>
                          <AudioRecorder
                            timeStartPrueba={timeStartPrueba}
                            tiempoLimite={respPrueba.tiempoLimite}
                            seconds={seconds}
                            pregunta={pregunta.codPregunta}
                            opcion={opcion.codOpcion}
                            updateRespuestasSeleccionadas={
                              updateRespuestasSeleccionadas
                            }
                            updateSeguimiento={updateSeguimiento}
                            noReplay={respPrueba.otros.noReplay}
                            cerrarComponentes={cerrarComponentes}
                          />
                        </div>
                      ) : opcion.tipo === "pizarra" ? (
                        <div key={String(opcion.codOpcion)}>
                          <Pizarra
                            timeStartPrueba={timeStartPrueba}
                            tiempoLimite={respPrueba.tiempoLimite}
                            seconds={seconds}
                            pregunta={pregunta.codPregunta}
                            alto={pregunta.otros.alto}
                            ancho={pregunta.otros.ancho}
                            opcion={opcion.codOpcion}
                            updateRespuestasSeleccionadas={
                              updateRespuestasSeleccionadas
                            }
                            updateSeguimiento={updateSeguimiento}
                            recurso={opcion.texto}
                          />
                        </div>
                      ) : (
                        "neutral"
                      )
                    )
                  )}
                </div>
                {/*</FormControl>*/}
                <hr />
              </Grid>
              {/*<Grid item xs={2} />*/}
            </Grid>
          ))}
    </>
  );
};

export default DetailTest;