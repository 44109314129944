import React from 'react';
import {
  Typography,
  Grid,
  CardMedia
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import MuiAppBar from "@mui/material/AppBar";
import IntervaloTiempo from '../../components/IntervaloTiempo';
import { useSelector } from "react-redux";

const RespuestasPendientes = ({respuestasPendientes}) => {
  const { respuestasSeleccionadas } = useSelector((state) => state.test);
  return (
    <>
      <Grid
        item
        sm={12}
        md={4}
        sx={{
          padding: "5px",
        }}
      >
        <Typography variant="body1">
          <b>Respuestas registradas:</b>
          {" "}{respuestasSeleccionadas}/{respuestasPendientes}
        </Typography>
      </Grid>
    </>
  );
};

const AppBar = (props) => {
  const { respPrueba,
    stateInicioPrueba,
    statePruebaFinalizada,
    respuestasPendientes,
    stateMensajeIntentoFinPrueba,
    mostrarRI,
    testFinalizado,
    seconds,
    setSeconds,
    timeStartPrueba
  } = props;
  return (
    <MuiAppBar
      position="fixed"
      color="inherit"
      open={true}
      sx={{ zIndex: 1000 }}
    >
      <Toolbar
        sx={{
          display: "flex",
          direction: "columns",
          justifyContent: "space-between",
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            bgcolor: "background.paper",
            borderRadius: 1,
          }}
        >
          <Grid
            item
            sm={12}
            md={8}
            sx={{
              padding: "5px",
            }}
          >
            <Typography variant="h5">{respPrueba.nombre}</Typography>
          </Grid>
          {"estudiante" in respPrueba && (
            <Grid
              item
              sm={12}
              md={4}
              sx={{
                alignContent: "center",
              }}
            >
              <Typography variant="h6">
                Estudiante: {respPrueba.estudiante.nombre}
              </Typography>
            </Grid>
          )}
          {stateInicioPrueba && !statePruebaFinalizada && (
            <>
              {respPrueba.edad && (
                <Grid
                  item
                  display={{
                    xs: "none",
                    sm: "none",
                    md: "block",
                  }}
                  md={4}
                  sx={{
                    padding: "5px",
                  }}
                >
                  <Typography variant="body1">
                    <b>Edad:</b> {respPrueba.edad.años} Años,
                    {respPrueba.edad.meses} Meses, {respPrueba.edad.dias} Días
                  </Typography>
                </Grid>
              )}
              <Grid
                item
                sm={12}
                md={4}
                sx={{
                  padding: "5px",
                }}
              >
                {respPrueba.tiempoLimite > 0 && !mostrarRI && (
                  <div>
                    <IntervaloTiempo
                      testFinalizado={testFinalizado}
                      seconds={seconds}
                      setSeconds={setSeconds}
                      startTimePrueba={timeStartPrueba}
                    />
                  </div>
                )}
              </Grid>
              <RespuestasPendientes
                respuestasPendientes={respuestasPendientes} />
              {stateMensajeIntentoFinPrueba !== "" && (
                <Grid
                  item
                  sm={12}
                  md={3}
                  sx={{
                    padding: "0.5em",
                  }}
                >
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ color: "red" }}
                  >
                    {stateMensajeIntentoFinPrueba}
                  </Typography>
                </Grid>
              )}
              {respPrueba.recursoEstatico && (
                <Grid
                  item
                  sm={12}
                  md={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CardMedia
                    style={{
                      width: "auto",
                      height: "auto",
                      maxHeight: 500,
                      maxWidth: 900,
                      //padding: "0.5em",
                    }}
                    component="img"
                    src={respPrueba.recursoEstatico.recurso}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Toolbar>
    </MuiAppBar>
  )
}

export default AppBar