import * as React from "react";
import { ThemeProvider } from "@mui/material";
import theme from "data/themeConfig";
import RouterApi from "config/router";
import { Provider } from "react-redux";
import store from "libs/redux/store";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <RouterApi />
      </Provider>
    </ThemeProvider>
  );
}

export default App;
