import React, { useState } from "react";
import { Paper, Grid } from "@mui/material";
import HeaderPage from "components/HeaderPage";
import Loading from "components/Loading";
import Title from "components/Title";
import AssingTestForm from "../components/AssingTestForm";

const AssignTest = () => {
  const [openLoading, setOpenLoading] = useState(false);
  return (
    <>
      <Loading openLoading={openLoading} />
      <Title title="Dashboard - Asignar Pruebas" />
      <HeaderPage descripcion={"Asignar Pruebas"} />
      <Paper
        elevation={5}
        sx={{
          margin: "1em",
          //minHeight: "86vh",
          //minWidth: "85vh",
        }}
      >
        <AssingTestForm setOpenLoading={setOpenLoading} />
      </Paper>
    </>
  );
};

export default AssignTest;
