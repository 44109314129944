import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { styled, useTheme } from "@mui/material/styles";
import { TextDecreaseOutlined } from "@mui/icons-material";

const HeaderPage = ({ descripcion, pt }) => {
  const theme = useTheme();
  return (
    <>
      <CssBaseline />
      <Grid
        item
        sx={{
          color: theme.palette.primary.main,
          pl: "1em",
          pt: pt,
        }}
      >
        <Typography variant="h6" component="h1" sx={{ pl: "10px" }}>
          {descripcion}
        </Typography>
      </Grid>
      {/*<Grid item sx={{ pl: '2em', pb: '0.5em' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="secondary.main" href="/dashboard">
            Dashboard
          </Link>
          <Link
            underline="hover"
            color="secondary.main"
            href="#"
          >
            Core
          </Link>
          <Typography color="text.primary">Breadcrumbs</Typography>
        </Breadcrumbs>
      </Grid>      
      */}
    </>
  );
};

export default HeaderPage;
