import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import ButtonIcon from "components/ui/Buttons/ButtonIcon";
import TextFieldCustom from "components/ui/TextFields/TextFieldCustom";
import {
  showAlertConfirm,
  showAlertError,
  showAlertInput,
  showAlertSuccess,
} from "utils/alert";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import cache from "data/cache";
import userService from "services/user.services";

const initialValues = {
  username: "",
  name: "",
  rol: "",
};
const InfoUserForm = ({ setOpenLoading }) => {
  const [currentUser, setCurrentUser] = useState({});
  const navigate = useNavigate();
  const changePass = async (data) => {
    try {
      setOpenLoading(true);
      var response = await userService.changePass(data);
      if (response.status === 200) {
        setOpenLoading(false);
        showAlertSuccess("Contraseña actualizada");
      }
    } catch (error) {
      setOpenLoading(false);
      showAlertError("Error cambiar la contraseña");
    }
  };
  const onClickPass = (values) => {
    showAlertConfirm(
      "warning",
      "¿Desea cambiar la contraseña?",
      "Al cambiar la contraseña se cerrara la sesión.",
      async function (confirmed) {
        if (confirmed) {
          showAlertInput(
            "Digite la nueva contraseña",
            "¡Debe digitar una contraseña!",
            async function (result) {
              if (result.isConfirmed) {
                console.log(result.value);
                let data = {
                  cuenta: values.username,
                  passwd: result.value,
                };
                await changePass(data).then(() => {
                  navigate("/login", { replace: true });
                  cache.clearCache();
                });
              }
            }
          );
        }
      }
    );
  };
  useEffect(() => {
    let user = cache.getCacheJSON("appUser");
    if (user) {
      const savedData = {
        username: user.user.username,
        name:
          (!user.user.nombre_1 ? "" : user.user.nombre_1 + " ") +
          (!user.user.nombre_2 ? "" : user.user.nombre_2 + " ") +
          (!user.user.apellido_1 ? "" : user.user.apellido_1 + " ") +
          (!user.user.apellido_2 ? "" : user.user.apellido_2 + " "),
        rol: !user.rol.descripcion ? "" : user.rol.descripcion + " ",
      };
      setCurrentUser(savedData);
    }
  }, []);
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={currentUser || initialValues}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
          setFieldValue,
          isLoading,
          /* and other goodies */
        }) => (
          <>
            {/* username */}
            <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "0.5em" }}>
              <TextFieldCustom
                disabled
                autoFocus={true}
                name="username"
                label="Usuario"
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.username}
                value={values.username || ""}
                error={errors.username}
              />
            </Grid>
            {/* name */}
            <Grid item xs={12} sm={12} md={6} lg={4} sx={{ padding: "0.5em" }}>
              <TextFieldCustom
                disabled
                name="name"
                label="Nombre"
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.name}
                value={values.name || ""}
                error={errors.name}
              />
            </Grid>
            {/* rol */}
            <Grid item xs={12} sm={12} md={6} lg={3} sx={{ padding: "0.5em" }}>
              <TextFieldCustom
                disabled
                name="rol"
                label="Rol"
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.rol}
                value={values.rol || ""}
                error={errors.rol}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "0.5em" }}>
              <ButtonIcon
                id="btnSubmit"
                fullWidth
                iconname="key"
                description="Cambiar"
                color="primary"
                onClick={() => onClickPass(values)}
              />
            </Grid>
          </>
        )}
      </Formik>
    </>
  );
};

export default InfoUserForm;
