import React, { useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  tableCellClasses,
  Checkbox,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Swal from "sweetalert2";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const TableStudents = ({ estudiantes, setEstudiantes }) => {
  const [checkedAll, setCheckedAll] = useState(false);
  const setStudenstList = (checked, tempList) => {
    tempList.map((item) => (item.selected = checked));
    setEstudiantes(tempList);
    setCheckedAll(checked);
  };
  const hcCheckMain = (e) => {
    let tempList = [...estudiantes];
    let checked = false;
    let existePruebaFinaliza = tempList.filter((item) => item.prueba === true);
    if (existePruebaFinaliza.length > 0 && e.target.checked) {
      Swal.fire({
        icon: "warning",
        title: "!Existen estudiantes con pruebas finalizadas!",
        text: "¿Desea volvear asignar la prueba?",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Si",
        cancelButtonColor: "#d33",
        padding: "1rem",
      }).then((confirmed) => {
        checked = confirmed.value;
        setStudenstList(checked, tempList);
      });
    } else {
      checked = e.target.checked;
      setStudenstList(checked, tempList);
    }
  };
  const setStudents = (e, item) => {
    let tempList = [...estudiantes];
    tempList.map((est) => {
      if (est.id === item.id) {
        est.selected = item.selected;
      }
      return est;
    });
    const totalItems = estudiantes.length;
    const totalCheckedItems = tempList.filter((e) => e.selected).length;
    setEstudiantes(tempList);
    setCheckedAll(totalItems === totalCheckedItems);
  };
  const hcEstudiantes = (e, item) => {
    if (item.prueba && e.target.checked) {
      Swal.fire({
        icon: "warning",
        title: "!El estudiante tiene una prueba finalizada!",
        text: "¿Desea volvear asignar la prueba?",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Si",
        cancelButtonColor: "#d33",
        padding: "1rem",
      }).then((confirmed) => {
        item.selected = confirmed.value;
        setStudents(e, item);
      });
    } else {
      item.selected = e.target.checked;
      setStudents(e, item);
    }
  };
  return (
    <>
      {/* Estudiantes */}
      {estudiantes.length > 0 && (
        <>
          <Grid item sm={12} md={12} sx={{ pt: "0.5em", pl: "3em" }}>
            <Typography variant="h6" align="left" color="text.primary">
              Listado de estudiantes / Registros: {estudiantes.length}
            </Typography>
          </Grid>
          <Grid item sm={12} md={12} sx={{ padding: "1em" }}>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell width={"5%"} sx={{ pl: "15px" }}>
                      {/* <Checkbox color="secondary" /> */}
                      <Checkbox
                        id="mainCheck"
                        color="secondary"
                        checked={checkedAll}
                        onClick={(e) => hcCheckMain(e)}
                      />
                    </StyledTableCell>
                    <StyledTableCell width={"5%"}>#</StyledTableCell>
                    <StyledTableCell width={"20%"}>Codigo</StyledTableCell>
                    <StyledTableCell width={"50%"}>Nombre</StyledTableCell>
                    <StyledTableCell width={"20%"}>
                      Tiene prueba finalizada
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {estudiantes.map((item, index) => (
                    <StyledTableRow key={item.id}>
                      <StyledTableCell component="th" scope="row">
                        <Checkbox
                          color="secondary"
                          checked={item.selected}
                          onClick={(e) => hcEstudiantes(e, item)}
                        />
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {item.identificador}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {item.nombre}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {item.prueba ? "Si" : "No"}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </>
      )}
    </>
  );
};

export default TableStudents;
