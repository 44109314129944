import React, { useState } from "react";
import { Paper, Grid } from "@mui/material";
import HeaderPage from "components/HeaderPage";
import Loading from "components/Loading";
import Title from "components/Title";
import RegistroEstudianteForm from "../components/RegistroEstudianteForm";

const RegistroEstudiante = () => {
  const [openLoading, setOpenLoading] = useState(false);
  return (
    <>
      <Loading openLoading={openLoading} />
      <Title title="Estudiantes - Registro de datos" />
      <HeaderPage descripcion={"Estudiantes - Registro de datos"} />
      <Paper
        elevation={5}
        sx={{
          margin: "1em",
          minHeight: "86vh",
          //minWidth: "85vh",
        }}
      >
        <RegistroEstudianteForm setOpenLoading={setOpenLoading} />
      </Paper>
    </>
  );
};

export default RegistroEstudiante;
