import React, { useState, useEffect } from "react";
import {
    Grid,
    FormHelperText,
    InputLabel,
    FormControl,
    Box,
    MenuItem,
    Button,
    Container,
    Typography,
    AppBar,
    Toolbar,
} from "@mui/material";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { Formik } from "formik";
import testService from "services/test.services";
import moment from "moment";
import SelectCustom from "components/ui/Selects/SelectCustom";
import { showAlertError, showAlertInfo } from "utils/alert";
import { config } from "utils/config";
// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const initialValues = {
    fechaInicio: moment(),
    fechaFin: moment(),
    colegio: "",
    grado: "",
    grupo: "",
    tipoPrueba: "",
    prueba: "",
    codEstudiante: "",
};

const RecursosTestForm = ({ setOpenLoading }) => {
    const [tipoPruebas, setTipoPruebas] = useState([]);
    const [pruebasRecursos, setPruebasRecursos] = useState([]);
    const [pruebas, setPruebas] = useState([]);
    const [recursoIframe, setRecursoIframe] = useState(null);

    const server = config.API_RESOURCE_URL;

    async function getTipoPruebas() {
        try {
            setOpenLoading(true);
            var response = await testService.getTestType({ ocultar: 1 });
            if (response.status === 200) {
                setTipoPruebas(response.data.data);
                setOpenLoading(false);
            }
        } catch (error) {
            /*showAlert('error',
              '!Ha ocurrido un error!',
              'Al cargar los colegios');*/
            setOpenLoading(false);
        }
    }
    const getPruebas = async (tipoPrueba) => {
        try {
            setPruebas(tipoPruebas.find((e) => e.key === tipoPrueba).pruebas);
        } catch (error) {
            /*showAlert('error',
              '!Ha ocurrido un error!',
              'Al cargar los colegios');*/
        }
    };
    async function getRecursosPruebas(data, resetForm) {
        try {
            var response = await testService.recursos_GetRecursosByPrueba(data);
            if (response.status === 200) {
                setOpenLoading(false);
                setPruebasRecursos(response.data.data);
            }
        } catch (error) {
            setOpenLoading(false);
            setPruebasRecursos([]);
            setRecursoIframe(null);
            showAlertError("No se encontraron datos");
        }
    }
    const handleItemClick = (recurso) => {
        setRecursoIframe(
            `https://docs.google.com/viewer?url=${server}${recurso}&embedded=true`
        );
    };
    const renderTreeItemEstudiante = (item) => {
        const renderTreeItemPruebaWithItem = (child) => {
            return renderTreeItemPrueba(item, child);
        };

        return (
            <TreeItem
                key={item.key}
                nodeId={item.key}
                label={item.value} >
                {item.pruebas && item.pruebas.map(child => renderTreeItemPrueba(item, child))}
            </TreeItem>
        );
    };
    const renderTreeItemPrueba = (estudiante, prueba) => (
        <TreeItem
            nodeId={prueba.key}
            label={prueba.value}
            onClick={() => handleItemClick(prueba.recurso)} />
    );

    const initForm = () => {
        getTipoPruebas();
    };

    useEffect(() => {
        initForm();
    }, []);
    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    var data = null;
                    data = {
                        prueba: values.prueba
                    };
                    if (values.prueba) {
                        data.prueba = values.prueba;
                    }
                    getRecursosPruebas(data, resetForm);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    resetForm,
                    setFieldValue,
                }) => (
                    <Box
                        component="form"
                        noValidate
                        id="resutado-pruebas-form"
                        sx={{ mt: 1 }}
                    >
                        <Grid
                            container
                            sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                bgcolor: "background.paper",
                                borderRadius: 1,
                            }}
                        >
                            {/* Tipo Pruebas */}
                            <Grid item xs={12} sm={12} md={6} lg={3} sx={{ padding: "1em" }}>
                                <FormControl fullWidth error={errors.tipoPrueba}>
                                    <InputLabel id="input-label-inst">Tipos Pruebas*</InputLabel>
                                    <SelectCustom
                                        labelId="select-label-tipo-prueba"
                                        id="select-tipo-prueba"
                                        name="tipoPrueba"
                                        label="tipos pruebas*"
                                        onChange={(e) => {
                                            getPruebas(e.target.value);
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        value={values.tipoPrueba}
                                    >
                                        <MenuItem value="">--</MenuItem>
                                        {tipoPruebas &&
                                            tipoPruebas.map((item) => (
                                                <MenuItem key={item.key} value={item.key}>
                                                    {item.value}
                                                </MenuItem>
                                            ))}
                                    </SelectCustom>
                                    {errors.tipoPrueba && touched.tipoPrueba && (
                                        <FormHelperText>Campo Requerido</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            {/* Pruebas */}
                            <Grid item xs={12} sm={12} md={6} lg={3} sx={{ padding: "1em" }}>
                                <FormControl fullWidth error={errors.prueba}>
                                    <InputLabel id="input-label-inst">Pruebas*</InputLabel>
                                    <SelectCustom
                                        labelId="select-label-prueba"
                                        id="select-prueba"
                                        name="prueba"
                                        label="Pruebas*"
                                        value={values.prueba}
                                        onChange={(e) => {
                                            handleChange(e);
                                        }}
                                    >
                                        <MenuItem value="">--</MenuItem>
                                        {pruebas &&
                                            pruebas.map((item) => (
                                                <MenuItem key={item.key} value={item.key}>
                                                    {item.value}
                                                </MenuItem>
                                            ))}
                                    </SelectCustom>
                                    {errors.prueba && touched.prueba && (
                                        <FormHelperText>Campo Requerido</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            {/* Buscar */}
                            <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    sx={{ textTransform: "none", height: "3.5em" }}
                                    startIcon={<SearchTwoToneIcon />}
                                    onClick={() => {
                                        handleSubmit();
                                    }}
                                >
                                    Buscar
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </Formik >
            <Grid container spacing={2} style={{ marginTop: "0.1em" }}>
                <Grid item xs={12} sm={12} md={6} lg={4} sx={{ padding: "1em" }}>
                    <Container maxWidth="sm">
                        <Box sx={{ flexGrow: 1 }}>
                            <AppBar position="static">
                                <Toolbar>
                                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                        Pruebas / Recursos
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                        </Box>
                        <div style={{ marginTop: "1em" }}>
                            <TreeView
                                defaultCollapseIcon={<ExpandMoreIcon />}
                                defaultExpandIcon={<ChevronRightIcon />}
                            >
                                {renderTreeItemEstudiante(pruebasRecursos)}
                            </TreeView>
                        </div>

                    </Container>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={8} sx={{ padding: "1em" }}>
                    <iframe
                        title="recursoIfram"
                        src={recursoIframe}
                        height="700px"
                        width="100%"
                        frameBorder="0"
                    ></iframe>
                </Grid>
            </Grid >
        </>
    );
};

export default RecursosTestForm;
