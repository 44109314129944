import Swal from "sweetalert2";

const showAlert = (icon, title, text) => {
  Swal.fire({
    icon,
    title,
    text,
    padding: "1rem",
  });
};

const showAlertError = (text) => {
  Swal.fire({
    icon: "error",
    title: "!Error!",
    text,
    padding: "1rem",
  });
};

const showAlertSuccess = (text) => {
  Swal.fire({
    icon: "success",
    title: "!OK!",
    text,
    padding: "1rem",
  });
};

const showAlertInfo = (text) => {
  Swal.fire({
    icon: "info",
    title: "!Info!",
    text,
    padding: "1rem",
  });
};

const showAlertWarning = (text) => {
  Swal.fire({
    icon: "warning",
    title: "!Advertencia!",
    text,
    padding: "1rem",
  });
};

const showAlertConfirm = (icon, title, text, callback) => {
  Swal.fire({
    icon,
    title,
    text,
    showCancelButton: true,
    cancelButtonText: "No",
    confirmButtonText: "Si",
    cancelButtonColor: "#d33",
    confirmButtonColor: "#4caf50",
    padding: "1rem",
  }).then((confirmed) => {
    callback(confirmed && confirmed.value === true);
  });
};

const showAlertInput = (title, message, callback) => {
  Swal.fire({
    title,
    input: "text",
    inputAttributes: {
      autocapitalize: "off",
    },
    showCancelButton: true,
    confirmButtonText: "Confirmar",
    showLoaderOnConfirm: true,
    allowOutsideClick: () => !Swal.isLoading(),
    preConfirm: async (result) => {
      try {
        if (result === null || result === undefined || result === "") {
          Swal.showValidationMessage(message);
        }
        return result;
      } catch (error) {
        Swal.showValidationMessage(`Ha ocurrido un error: ${error}`);
      }
    },
  }).then((result) => {
    callback(result);
  });
};

const showAlertImage = (image) => {
  Swal.fire({
    icon: "info",
    title: "Imagen",
    text: "<img src='" + image + "' style='width:150px;'>",
    html: true,
  });
}

export {
  showAlert,
  showAlertConfirm,
  showAlertError,
  showAlertSuccess,
  showAlertInfo,
  showAlertWarning,
  showAlertInput,
  showAlertImage
};
