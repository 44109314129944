import React, { useState, useRef, useEffect } from "react";
import { Button, Grid } from "@mui/material";
import AudioPlayer from "./AudioPlayer";
import { useTheme } from "@mui/material/styles";

const AudioRecorder = (props) => {
  const [recording, setRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [error, setError] = useState(null);
  const audioRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const theme = useTheme();
  useEffect(() => {    
    if (props.cerrarComponentes && recording) {
      stopRecording();
    }
  }, [props.cerrarComponentes]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      audioRef.current = new Audio();
      audioRef.current.srcObject = stream;
      mediaRecorderRef.current = new MediaRecorder(stream);
      const chunks = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        chunks.push(event.data);
      };
      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunks, { type: "audio/webm" });
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
          if (!props.noReplay) {
            setAudioUrl(reader.result);
          }
          let tiempoRespuesta = props.tiempoLimite - props.seconds;
          if (props.tiempoLimite < 0) {
            tiempoRespuesta = parseInt(
              (Date.now() - props.timeStartPrueba) / 1000
            );
          }

          let pregunta = props.pregunta;
          let respuesta = props.opcion;
          let textoInputRespuesta = reader.result;

          props.updateRespuestasSeleccionadas(
            parseInt(pregunta),
            parseInt(respuesta),
            parseInt(tiempoRespuesta),
            null,
            textoInputRespuesta
          );

          props.updateSeguimiento(
            "Respuesta seleccionada",
            `Pregunta:${pregunta};Respuesta:${respuesta};TiempoRespuesta:${tiempoRespuesta};RespuestaAutomatica:${textoInputRespuesta}`
          );
        };
      };
      mediaRecorderRef.current.start();
      setRecording(true);
    } catch (err) {
      setError(err);
    }
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    setRecording(false);
    audioRef.current.srcObject.getTracks().forEach((track) => track.stop());
  };

  return (
    <Grid
      container
      component="main"
      sx={{
        display: "flex",
        borderRadius: 1,
      }}
    >
      {error && <p>{error.message}</p>}
      <Grid item md={2} sx={{ padding: "0.5em" }}>
        {recording ? (
          <Button
            onClick={stopRecording}
            variant="outlined"
            fullWidth
            color="error"
            sx={{
              "&:hover": {
                color: theme.palette.secondary.contrastText,
              },
            }}
          >
            Detener grabación
          </Button>
        ) : (
          <Button
            onClick={startRecording}
            fullWidth
            variant="outlined"
            sx={{
              "&:hover": {
                color: theme.palette.secondary.contrastText,
              },
            }}
          >
            Iniciar grabación
          </Button>
        )}
      </Grid>
      <Grid item sm={12} md={6}>
        {audioUrl && (
          <>
            <div>
              <AudioPlayer url={audioUrl} validarEjecuciones={false} />
            </div>
          </>
        )}
      </Grid>
      {/*<Grid item sm={false} md={6}></Grid>*/}
    </Grid>
  );
};

export default AudioRecorder;
{
  /*<div>
            <audio src={audioUrl} controls onEnded={console.log("YES")} />
          </div>*/
}
