import React from "react";
import { Button, Icon } from "@mui/material";
import { styled } from "@mui/material/styles";

const ButtonStyle = styled(Button)(({ theme }) => ({
  boxShadow: "none",
  textTransform: "none",
  borderRadius: "0.5em",
  height: "3em",
  //marginTop: '1.5em',
}));

const ButtonIcon = (props) => {
  return (
    <ButtonStyle
      type="button"
      variant={props.variant != null ? props.variant : "contained"}
      endIcon={<Icon color="inherit">{props.iconname}</Icon>}
      {...props}
    >
      {props.description ? props.description : ""}
    </ButtonStyle>
  );
};

export default ButtonIcon;
