import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
//import Divider from '@mui/material/Divider';
import {
  Grid,
  Divider,
  Menu,
  Button,
  Typography,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import cache from "data/cache";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const SideBarMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentUser /*, setCurrentUser*/] = useState(
    cache.getCacheJSON("appUser")
  );
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (e, route) => {
    if (route.includes("dashboard")) {
      navigate(route, { replace: true });
    } else {
      navigate(route, { replace: true });
      cache.clearCache();
    }
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{ textTransform: "none", height: "3.5em" }}
      >
        Mis Datos
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={(e) => handleMenuItemClick(e, "/dashboard/user-info")} disableRipple>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              borderRadius: 1,
            }}
          >
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom noWrap component="div">
                Usuario
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ pt: "5px" }}>
              <Typography variant="h7" noWrap component="div">
                {(!currentUser.user.nombre_1
                  ? ""
                  : currentUser.user.nombre_1 + " ") +
                  (!currentUser.user.nombre_2
                    ? ""
                    : currentUser.user.nombre_2 + " ") +
                  (!currentUser.user.apellido_1
                    ? ""
                    : currentUser.user.apellido_1 + " ") +
                  (!currentUser.user.apellido_2
                    ? ""
                    : currentUser.user.apellido_2 + " ")}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ pt: "5px" }}>
              <Typography variant="h7" noWrap component="div">
                {!currentUser.rol.abreviatura
                  ? ""
                  : currentUser.rol.abreviatura + " "}
              </Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={(e) => handleMenuItemClick(e, "/dashboard")}
          disableRipple
        >
          Home
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={(e) => handleMenuItemClick(e, "/login")}
          disableRipple
        >
          Cerrar Sesión
        </MenuItem>
      </StyledMenu>
    </>
  );
};

export default SideBarMenu;
