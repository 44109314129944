import React from "react";
import {
  Divider,
  InputLabel,
  TextField,
  FormControl,
  MenuItem,
  FormHelperText,
  Box,
  Grid,
  Typography,
  IconButton,
  Icon,
  Stack,
} from "@mui/material";
import SelectCustom from "./ui/Selects/SelectCustom";
import ButtonIcon from "./ui/Buttons/ButtonIcon";
import ModalParams from "./ModalParams";
import { styled, useTheme } from "@mui/material/styles";

const QuickSearch = (props) => {
  const {
    isQuickSearch,
    data,
    setData,
    keyItem,
    valueItem,
    onClickItem,
    error,
    touched,
    component,
    ...others
  } = props;
  const widhtSelect = isQuickSearch === true ? 11 : 12;
  const theme = useTheme();
  return (
    <>
      <Grid
        container
        rowSpacing={4}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center"
        }}
      >
        <Grid
          item
          xs={widhtSelect}
          sm={widhtSelect}
          md={widhtSelect}
          lg={widhtSelect}
        >
          <FormControl fullWidth error={error && touched ? true : false}>
            <InputLabel id={"input-label-" + others.name}>
              {others.label}
            </InputLabel>
            {/* {data.length > 0 && ( */}
            <SelectCustom
              {...others}
              labelId={"select-label-" + others.name}
              id={"select" + others.name}
              error={error && touched ? true : false}
            >
              {data.map((item) => (
                <MenuItem
                  key={item[keyItem]}
                  value={item[keyItem]}
                  onClick={onClickItem}
                >
                  {item[valueItem]}
                </MenuItem>
              ))}
            </SelectCustom>
            {/* )} */}
            {error && touched && <FormHelperText>{error}</FormHelperText>}
          </FormControl>
        </Grid>
        {isQuickSearch === true && (
          <Grid
            item
            xs={1}
            sx={{
              // borderRadius: "0.5em",
              borderColor: theme.palette.secondary.light,
              // alignSelf: "left",
              // pt: "10px",
            }}
          >
            <ModalParams
              mostrar={false}
              component={component}
              setData={setData}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default QuickSearch;
