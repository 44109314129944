import React from "react";
import { TextField, Autocomplete } from "@mui/material";

const SelectAutocomplete = (props) => {
  const { name, label, id, desc, setFieldValue, ...others } = props;
  return (
    <Autocomplete
      {...others}
      key={others.options}
      disablePortal
      id={"select-" + name}
      onChange={(e, value) =>
        setFieldValue(props.name, value != null ? value[id] : "")
      }
      getOptionLabel={(option) => (option.value ? option[desc] : "")}
      isOptionEqualToValue={(option, value) => option[id] === value[id]}
      //defaultValue={savedData != null ? savedData.typeDocument_id : null}//{{ name: "NIT", id: "62c7a68497f9a1c40231507f" }}
      //loading={isLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          error={Boolean(props.touched && props.error)}
          fullWidth
          helperText={props.touched && props.error}
          label={label}
          name={name}
          variant="outlined"
        />
      )}
    />
  );
};

export default SelectAutocomplete;

/*
Ejemplo de uso

<SelectAutocomplete
                  name="prueba"
                  label="Pruebas*"
                  onOpen={handleBlur}
                  setFieldValue={setFieldValue}
                  options={pruebas}
                  error={errors.prueba}
                  touched={String(touched.prueba)}
                  id="key"
                  desc="value"
                />
*/
