import authService from "services/auth";
import cache from "data/cache";

export const useLogin = () => {
  const getSession = async (data) => {
    let appUser = {
      user: "",
      rol: "",
      office: "",
    };
    let response = await authService.login(data);
    if (response.status === 200) {
      let token = response.data.token;
      appUser.user =
        response.data.estudiante != null
          ? response.data.estudiante
          : response.data.participante;
      appUser.rol = response.data.rol;
      appUser.user.username = data.username;
      cache.setCache("appToken", token);
      cache.setCacheJSON("appUser", appUser);
      cache.setCache("appEnvironment", response.data.ambiente);
    }
  };
  return {
    getSession,
  };
};

export default useLogin;
