import client from "libs/client";

const getSchools = async () => {
  let response = await client("POST", {}, "/schools");
  return response;
};

const getGrades = async (data) => {
  let response = await client("POST", data, "/schools/grade");
  return response;
};

const getClassRoom = async (data) => {
  var response = await client("POST", data, "/schools/classroom");
  return response;
};

const getStudents = async (data) => {
  let response = await client("POST", data, "/students");
  return response;
};

const paramsService = {
  getSchools,
  getGrades,
  getClassRoom,
  getStudents,
};

export default paramsService;
