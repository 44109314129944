import client from "libs/client";

const getTipoParticipantes = async () => {
  let response = await client("POST", {}, "/participants/type");
  return response;
};

const getParticipantes = async (data) => {
  let response = await client("POST", data, "/participants");
  return response;
};

const getTipoEncuestas = async () => {
  let response = await client("POST", {}, "/tests/poll/type");
  return response;
};

const getEstudiantes = async (data) => {
  let response = await client("POST", data, "/students/all");
  return response;
};

const asignarPrueba = async (data) => {
  let response = await client("POST", data, "/tests/poll/assign");
  return response;
};

const surveyService = {
  getTipoParticipantes,
  getParticipantes,
  getTipoEncuestas,
  getEstudiantes,
  asignarPrueba,
};

export default surveyService;
