import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import imagenAV from 'assets/page_error.jpg';
import CardMedia from '@mui/material/CardMedia';

const PageError = () => {
  return (
    <>
      <Box component="main">
        <Grid container spacing={2} style={{ display: 'flex'}}>
          <Grid item sx={{ display: 'flex', justifyContent: 'space-between', alignItems:'center'}}>
            <CardMedia
              sx={{ objectFit: 'contain', height: '90vh' }}
              component="img"
              image={imagenAV}
              alt="Img"
            />
            <Grid item xs>
              <Typography
                component="h1"
                variant="h2"
                align="left"
                color="text.primary"
                gutterBottom
              >
                Oops!, Error 404
              </Typography>
              <Typography
                variant="h5"
                align="left"
                color="text.secondary"
                paragraph
              >
                Page not found
              </Typography>
            </Grid>

          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default PageError