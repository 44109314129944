import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { faker } from "@faker-js/faker";
import { useNavigate } from "react-router-dom";
import testService from "services/test.services";

function TestListForm({ setOpenLoading }) {
  const navigate = useNavigate();
  const handleClick = (route, idPrueba) => {
    navigate("/dashboard" + route + "/" + idPrueba);
  };
  const [listPruebas, setListPruebas] = useState([]);
  async function getPruebasAsignadas() {
    try {
      setOpenLoading(true);
      var response = await testService.getAssingmentsTest();
      if (response.status === 200) {
        if (response.data.data.length > 0) setListPruebas(response.data.data);
        setOpenLoading(false);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
      setOpenLoading(false);
    }
  }
  useEffect(() => {
    getPruebasAsignadas();
  }, []);
  return (
    <>
      {listPruebas.length > 0 ? (
        listPruebas.map((prueba) => (
          <Grid
            item
            key={prueba.prueba}
            sx={{
              padding: "1em 2em 1em 1em",
            }}
          >
            <Card elevation={6} sx={{ height: 400, width: 270 }}>
              <CardContent
                sx={{
                  p: 1,
                  m: 1,
                  height: 150,
                  bgcolor: "background.paper",
                  borderRadius: 1,
                }}
              >
                <Typography gutterBottom variant="h5" component="div">
                  {prueba.nombre}
                </Typography>
                {"estudiante" in prueba && (
                  <Typography gutterBottom variant="h6" component="div">
                    {prueba.estudiante}
                  </Typography>
                )}
              </CardContent>
              <CardMedia
                component="img"
                alt="imagen"
                height={170}
                image={
                  prueba.imgFondo
                    ? prueba.imgFondo
                    : faker.image.nature(null, null, true)
                }
              />
              <CardActions
                sx={{
                  p: 1,
                  m: 1,
                  bgcolor: "background.paper",
                  borderRadius: 1,
                }}
              >
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => handleClick("/test", prueba.evaluacion)}
                >
                  Comenzar
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))
      ) : (
        <Typography gutterBottom variant="subtitle1" component="div">
          Usted no tiene pruebas asignadas
        </Typography>
      )}
    </>
  );
}

export default TestListForm;
