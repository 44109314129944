import { createSlice } from "@reduxjs/toolkit";

const initialState = { respuestas: [], respuestasSeleccionadas: 0 };

export const testSlice = createSlice({
  name: "test",
  initialState,
  reducers: {
    resetState: () => initialState,
    asignarPruebas: (state, action) => {
      const { respuestas, respuestasSeleccionadas } = action.payload;
      state.respuestas = respuestas;
      state.respuestasSeleccionadas = respuestasSeleccionadas;
    },
  },
});

// this is for dispatch
export const { resetState, asignarPruebas } = testSlice.actions;

// this is for configureStore
export default testSlice.reducer;
