import React from "react";
import { Helmet } from "react-helmet-async";

const Title = (props) => {
  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
    </div>
  );
};

export default Title;
