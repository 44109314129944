import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";

const IntervaloTiempo = (props) => {
  const secondsDuracion = props.seconds;
  const [colorDanger, setColorDanger] = useState("black");

  useEffect(() => {
    const interval = setInterval(() => {
      let secondsElapsed = parseInt(
        (Date.now() - props.startTimePrueba) / 1000
      );
      var temp = secondsDuracion - secondsElapsed;
      props.setSeconds(temp);
      if (temp <= 0) {
        props.testFinalizado({ seconds: 0 });
        return clearInterval(interval);
      }
    }, 500);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (props.seconds < 30) setColorDanger("red");
  }, [props.seconds]);

  return (
    <Typography variant="body1" color={colorDanger}>
      <b>Tiempo restante:</b> {parseInt(props.seconds / 60, 0)}:
      {props.seconds - 60 * parseInt(props.seconds / 60, 0)}
    </Typography>
  );
};

export default IntervaloTiempo;
