import React from "react";
import { TextField } from "@mui/material";

const TextFieldCustom = (props) => {
  const { name, error, touched, ...others } = props;
  return (
    <TextField
      {...others}
      //margin="normal"
      fullWidth
      id={name}
      error={error ? true : false}
      helperText={error && touched ? error : null}
    />
  );
};

export default TextFieldCustom;

/*
Ejemplo de uso
<TextFieldCustom
  name="logo"
  label="Logo"
  onChange={handleChange}
  onBlur={handleBlur}
  touched={String(touched.logo)}
  value={values.logo || ""}
  error={errors.logo}
/>
*/
