import React, { useState, useEffect } from "react";
import { Grid, Box, Paper } from "@mui/material";
import Loading from "components/Loading";
import HeaderPage from "components/HeaderPage";
import Title from "components/Title";
import TextFieldCustom from "components/ui/TextFields/TextFieldCustom";
import InfoUserForm from "../Components/InfoUserForm";

const InfoUser = () => {
  const [openLoading, setOpenLoading] = useState(false);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Loading openLoading={openLoading} />
      <Title title="Dashboard - Información de usuario" />
      <HeaderPage descripcion={"Información de usuario"} />
      <Paper
        elevation={5}
        sx={{
          margin: "1em",
          //minWidth: "85vh",
        }}
      >
        <Box component="form" noValidate id="user-form" sx={{ mt: 1 }}>
          {/* Page */}
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              bgcolor: "background.paper",
              borderRadius: 1,
              padding: "1em",
            }}
          >
            <InfoUserForm setOpenLoading={setOpenLoading} />
          </Grid>
        </Box>
      </Paper>
    </>
  );
};

export default InfoUser;
