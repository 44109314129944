import React, { useState } from "react";
import { Paper, Grid } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import HeaderPage from "components/HeaderPage";
import Title from "components/Title";
import SurveyForStudent from "../components/SurveyForStudentForm";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AssignSurvey = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Title title="Dashboard - Asignar Evaluación" />
      <HeaderPage descripcion={"Asignar Evaluación"} />
      <Paper
        elevation={5}
        sx={{
          margin: "1em",
          minHeight: "86vh",
          //minWidth: "85vh",
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            variant="scrollable"
          >
            <Tab label="Evaluaciones sobre estudiantes" {...a11yProps(0)} />
            <Tab label="Evaluaciones generales" disabled {...a11yProps(1)} />
          </Tabs>
        </Box>
        <Box /*sx={{ padding: 2 }}*/>
          {value === 0 && <SurveyForStudent />}
          {value === 1 && (
            <Box>
              <Typography>The second tab</Typography>
            </Box>
          )}
        </Box>
      </Paper>
    </>
  );
};

export default AssignSurvey;
