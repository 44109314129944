import client from "libs/client";

const login = async (data) => {
  let response = await client("POST", data, "/auth/login");
  return response;
};

const authService = {
  login,
};

export default authService;
