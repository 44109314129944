import client from "libs/client";

const getEnumsByTipoEnum = async (data) => {
    let response = await client("POST", data, "/admin/enums");
    return response;
};
const adminService = {
    getEnumsByTipoEnum
};

export default adminService;