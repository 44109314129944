import React, { useEffect } from "react";
import { Typography } from "@mui/material";
const Timer = (props) => {
  const secondsDuracion = props.seconds;
  useEffect(() => {
    const interval = setInterval(() => {
      let secondsElapsed = parseInt((Date.now() - props.starQuestion) / 1000);
      var temp = secondsDuracion - secondsElapsed;
      props.setSeconds(temp);
      if (temp <= 0) {
        if (props.lastQuestion) {
          props.testFinalizado();
        } else {
          props.handleNext();
        }

        return clearInterval(interval);
      }
    }, 500);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Typography variant="h5" gutterBottom align="center">
        Tiempo restante: {parseInt(props.seconds / 60, 0)}:
        {props.seconds - 60 * parseInt(props.seconds / 60, 0)}
      </Typography>
    </>
  );
};

export default Timer;
