import React, { useState } from 'react';
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide
} from "@mui/material";
import MostrarRecurso from './MostrarRecurso';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CargarRecursoInicial = ({
  mostrarRI,
  setMostrarRI,
  tipo,
  recurso,
  repeticion,
  setTimeStartPrueba,
}) => {
  const [repeticiones, setRepeticiones] = useState(repeticion);
  const handleClickOpen = () => {
    setMostrarRI(true);
  };

  const handleClose = () => {
    let temp = repeticiones - 1;
    setRepeticiones(temp);
    if (temp === 0) {
      setMostrarRI(false);
      setTimeStartPrueba(Date.now());
    }
  };
  return (
    <>
      <Dialog
        open={mostrarRI}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogContent>
          <MostrarRecurso data={{ recurso, tipo }} />
          <Typography variant="h6" gutterBottom>
            Número de repeticiones: {repeticion - repeticiones + 1}/{repeticion}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CargarRecursoInicial