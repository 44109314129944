import React from 'react';
import {
  Typography,
  CardMedia,
} from "@mui/material";
import Instruccion from "../../components/Instruccion";
import { config } from "utils/config";

const cardImage = {
  width: "auto",
  height: "auto",
  maxHeight: 280,
  maxWidth: 216,
  padding: "0.5em",
};

const MostrarRecurso = (props) => {
  const server = config.API_RESOURCE_URL;
  switch (props.data.tipo) {
    case "imagen":
      return (
        <CardMedia
          style={cardImage}
          component="img"
          src={server + props.data.recurso}
        />
      );
    case "texto":
      return (
        <Typography variant="h6" gutterBottom>
          {props.data.recurso}
        </Typography>
      );
    case "video":
      return (
        <CardMedia
          style={cardImage}
          component="video"
          src={server + props.data.recurso}
        />
      );
    case "audio":
      return (
        <CardMedia
          style={cardImage}
          component="audio"
          src={server + props.data.recurso}
        />
      );
    case "html":
      return <Instruccion instruccion={props.data.recurso} />;
    default:
      return "neutral";
  }
}

export default MostrarRecurso