import React, { useState, useEffect } from "react";
import {
  Grid,
  FormHelperText,
  InputLabel,
  FormControl,
  TextField,
  Box,
  MenuItem,
  Button,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  tableCellClasses,
  Divider,
  Tooltip,
  IconButton
} from "@mui/material";

import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";

import SaveIcon from "@mui/icons-material/Save";
import AddBoxIcon from '@mui/icons-material/AddBox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ErrorIcon from '@mui/icons-material/Error';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';

import { Formik } from "formik";
import * as Yup from "yup";
import SelectCustom from "components/ui/Selects/SelectCustom";
import paramsService from "services/params.services";
import adminService from "services/admin.services";
import { showAlertSuccess, showAlertWarning } from "utils/alert";

import moment from "moment";
import DateTimer from "components/DateTimer";
import estudianteService from "services/estudiantes.services";

let submitAction;

const initialValues = {
  id_ie: null,
  id_curso: null,
  id_grupo: null,
  t_doc: null,
  num_doc: null,
  pri_nom: null,
  seg_nom: null,
  pri_ape: null,
  seg_ape: null,
  fec_nac: null,
  sexo: null,
  estrato: null,
};

const formSchema = Yup.object().shape({
  id_ie: Yup.string(),
  id_curso: Yup.string(),
  grupo: Yup.string(),
  codEstudiante: Yup.string(),
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function RegistroEstudianteForm({ setOpenLoading }) {
  const [valueTemp, setValueTemp] = useState(null);

  const [colegios, setColegios] = useState([]);
  // Enums
  const [enumGrados, setEnumGrados] = useState([]);
  const [enumGrupos, setEnumGrupos] = useState([]);
  const [enumGeneros, setEnumGeneros] = useState([]);
  const [enumEstratos, setEnumEstratos] = useState([]);
  const [enumTiposDoc, setEnumTiposDoc] = useState([]);

  const [estudiantes, setEstudiantes] = useState([]);

  const theme = useTheme();

  async function getEnumGeneros() {
    try {
      var response = await adminService.getEnumsByTipoEnum({ tipo: 2 });
      if (response.status === 200) {
        setEnumGeneros(response.data.data);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  async function getEnumEstratos() {
    try {
      var response = await adminService.getEnumsByTipoEnum({ tipo: 11 });
      if (response.status === 200) {
        setEnumEstratos(response.data.data);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  async function getEnumCursos() {
    try {
      var response = await adminService.getEnumsByTipoEnum({ tipo: 6 });
      if (response.status === 200) {
        setEnumGrados(response.data.data);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  async function getEnumGrupos() {
    try {
      var response = await adminService.getEnumsByTipoEnum({ tipo: 8 });
      if (response.status === 200) {
        setEnumGrupos(response.data.data);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  async function getEnumTiposDoc() {
    try {
      var response = await adminService.getEnumsByTipoEnum({ tipo: 1 });
      if (response.status === 200) {
        setEnumTiposDoc(response.data.data);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  async function getColegios() {
    try {
      var response = await paramsService.getSchools();
      if (response.status === 200) {
        setColegios(response.data.data);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  async function estudiantesCreate(data) {
    try {
      let mensaje = "";
      const response = await estudianteService.createDatosEstudiantes(data);
      if (response.status === 200) {
        let data = response.data.data;
        setEstudiantes([...data])
        mensaje = "Proceso de actualización completado! Revise los comentarios en columna 'Resultado'";
      }
      const usuarios = await estudianteService.crearUsuarios();
      if (usuarios.status === 200) {
        mensaje += `. Cuentas creadas:  ${usuarios.data.creados}, Cuentas fallidas: ${usuarios.data.fallidos}`;
      }
      showAlertSuccess(mensaje);
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  const removeEstudiante = (index) => {
    let tempArray = [...estudiantes]
    tempArray.splice(index, 1);
    setEstudiantes(tempArray)
  }
  const getColegioById = (c) => {
    const found = colegios.find((element) => element.id === c);
    if (found === undefined) { return ''; }
    return found.nombre
  }
  const getGrupoById = (c) => {
    const found = enumGrupos.find((element) => element.id === c);
    if (found === undefined) { return ''; }
    return found.name
  }
  const getGradoById = (c) => {
    const found = enumGrados.find((element) => element.id === c);
    if (found === undefined) { return ''; }
    return found.name
  }
  const getEstratoById = (c) => {
    const found = enumEstratos.find((element) => element.id === c);
    if (found === undefined) { return ''; }
    return found.name
  }
  const getGeneroById = (c) => {
    const found = enumGeneros.find((element) => element.id === c);
    if (found === undefined) { return ''; }
    return found.name
  }
  const getTipoDocById = (c) => {
    const found = enumTiposDoc.find((element) => element.id === c);
    if (found === undefined) { return ''; }
    return found.name
  }

  const initForm = () => {
    getColegios();
    getEnumGeneros();
    getEnumEstratos();
    getEnumCursos();
    getEnumGrupos();
    getEnumTiposDoc();
  };
  useEffect(() => {
    initForm();
  }, []);

  return (
    <>
      {/* Estudiantes */}
      <Formik
        initialValues={initialValues}
        validationSchema={formSchema}
        validate={(values) => {
          const errors = {};
          if (!values.id_ie) {
            errors.id_ie = "Campo Requerido";
          }
          if (!values.id_curso) {
            errors.id_curso = "Campo Requerido";
          }
          if (!values.id_grupo) {
            errors.id_grupo = "Campo Requerido";
          }
          if (!values.pri_nom) {
            errors.pri_nom = "Campo Requerido";
          }
          if (!values.pri_ape) {
            errors.pri_ape = "Campo Requerido";
          }
          values.fec_nac = moment(valueTemp).format("YYYY-MM-DD")
          if (!values.fec_nac || values.fec_nac === 'Invalid date') {
            errors.fec_nac = "Campo Requerido";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (submitAction === "addToList") {
            let array = [...estudiantes]
            let obj = values

            for (const key in obj) {
              if (obj[key] == null) {
                delete obj[key];
              }
            }
            array.push(obj)
            setEstudiantes(array)

          } else {
            if (estudiantes.length > 0) {
              let data_estudiantes = {
                estudiantes: estudiantes,
              };
              estudiantesCreate(data_estudiantes)
            } else {
              showAlertWarning("No se han registrados cambios.")
            }
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
          /* and other goodies */
        }) => (
          <Box
            component="form"
            noValidate
            id="asignar-pruebas-form"
            sx={{ mt: 1 }}
          >
            {/* Toolbar */}
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                //background: theme.palette.secondary.main,
                background: theme.palette.secondary.light,
                padding: "0,5em",
                minHeight: "3em",
                //pt: "3em"
              }}
            >
              {/* Habilitar */}
              <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  sx={{ textTransform: "none", height: "3em" }}
                  startIcon={<SaveIcon />}
                  onClick={() => {
                    submitAction = "";
                    handleSubmit();
                  }}
                >
                  Registrar datos
                </Button>
              </Grid>
            </Grid>
            {/* Form */}
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                bgcolor: "background.paper",
                borderRadius: 1,
                padding: "1em",
              }}
            >
              {
                <>
                  {/* Colegios */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    sx={{ padding: "1em" }}
                  >
                    <FormControl
                      fullWidth
                      error={errors.id_ie ? true : false}
                    >
                      <InputLabel id="input-label-colegio">
                        Colegios*
                      </InputLabel>
                      <SelectCustom
                        labelId="select-label-colegio"
                        id="select-colegio"
                        name="id_ie"
                        label="colegios*"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.id_ie}
                      >
                        {colegios &&
                          colegios.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.nombre}
                            </MenuItem>
                          ))}
                      </SelectCustom>
                      {errors.id_ie && touched.id_ie && (
                        <FormHelperText>{errors.id_ie}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {/* Grados */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={2}
                    sx={{ padding: "1em" }}
                  >
                    <FormControl fullWidth error={errors.id_curso ? true : false}>
                      <InputLabel id="input-label-grado">Grados*</InputLabel>
                      <SelectCustom
                        labelId="select-label-grado"
                        id="select-grado"
                        name="id_curso"
                        label="grados*"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.id_curso}
                      >
                        {enumGrados &&
                          enumGrados.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                      </SelectCustom>
                      {errors.id_curso && touched.id_curso && (
                        <FormHelperText>{errors.id_curso}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {/* Grupos */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={2}
                    sx={{ padding: "1em" }}
                  >
                    <FormControl fullWidth error={errors.id_grupo ? true : false}>
                      <InputLabel id="input-label-inst">Grupos*</InputLabel>
                      <SelectCustom
                        labelId="select-label-grupo"
                        id="select-grupo"
                        name="id_grupo"
                        label="grupos*"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.id_grupo}
                      >
                        {enumGrupos &&
                          enumGrupos.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                      </SelectCustom>
                      {errors.id_grupo && touched.id_grupo && (
                        <FormHelperText>{errors.id_grupo}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </>
              }
            </Grid>
            <Divider sx={{
              marginBottom: "2em"
            }} />
            <Grid container spacing={2}>
              <Grid
                container
                xs={4}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  borderRadius: 1,
                  paddingLeft: "3em",
                  paddingTop: "0.6em",
                }}
              >
                <Grid item sm={12} md={12} sx={{ padding: "0.5em" }}>
                  <FormControl
                    fullWidth
                  >
                    <InputLabel id="select-label-t_doc">Tipo de documento</InputLabel>
                    <SelectCustom
                      labelId="select-label-t_doc"
                      id="select-t_doc"
                      name="t_doc"
                      label="Tipo de documento"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.t_doc}
                    >
                      {enumTiposDoc &&
                        enumTiposDoc.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name ?? ''}
                          </MenuItem>
                        ))}
                    </SelectCustom>
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={12} sx={{ padding: "0.5em" }}>
                  <FormControl
                    fullWidth
                  >
                    <TextField
                      label="Número de documento"
                      name="num_doc"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.num_doc}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={12} sx={{ padding: "0.5em" }}>
                  <FormControl
                    fullWidth
                    error={errors.pri_nom ? true : false}
                  >
                    <TextField
                      required
                      error={errors.pri_nom ? true : false}
                      label="Primer nombre"
                      name="pri_nom"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.pri_nom}
                    />
                    {errors.pri_nom && touched.pri_nom && (
                      <FormHelperText>{errors.pri_nom}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={12} sx={{ padding: "0.5em" }}>
                  <FormControl
                    fullWidth
                  >
                    <TextField
                      label="Segundo nombre"
                      name="seg_nom"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.seg_nom}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={12} sx={{ padding: "0.5em" }}>
                  <FormControl
                    fullWidth
                    error={errors.pri_ape ? true : false}
                  >
                    <TextField
                      required
                      error={errors.pri_ape ? true : false}
                      label="Primer apellido"
                      name="pri_ape"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.pri_ape}
                    />
                    {errors.pri_ape && touched.pri_ape && (
                      <FormHelperText>{errors.pri_ape}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={12} sx={{ padding: "0.5em" }}>
                  <FormControl
                    fullWidth
                  >
                    <TextField
                      label="Segundo apellido"
                      name="seg_ape"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.seg_ape}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={12} sx={{ padding: "0.5em" }}>
                  <FormControl
                    fullWidth
                    error={errors.fec_nac ? true : false}
                  >
                    <DateTimer
                      error={errors.fec_nac ? true : false}
                      name="fec_nac"
                      label="Fecha Nacimiento*"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      setValueTemp={setValueTemp}
                      initialDate={''}
                      value={values.fec_nac}
                    />
                    {errors.fec_nac && touched.fec_nac && (
                      <FormHelperText>{errors.fec_nac}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={12} sx={{ padding: "0.5em" }}>
                  <FormControl
                    fullWidth
                  >
                    <InputLabel id="select-label-sexo">Género</InputLabel>
                    <SelectCustom
                      label="Género"
                      labelId="select-label-sexo"
                      id="select-sexo"
                      name="sexo"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.sexo}
                    >
                      {enumGeneros &&
                        enumGeneros.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name ?? ''}
                          </MenuItem>
                        ))}
                    </SelectCustom>
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={12} sx={{ padding: "0.5em" }}>
                  <FormControl
                    fullWidth
                  >
                    <InputLabel id="select-label-estrato">Estrato</InputLabel>
                    <SelectCustom
                      label="Estrato"
                      labelId="select-label-estrato"
                      id="select-estrato"
                      name="estrato"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.estrato}
                    >
                      {enumEstratos &&
                        enumEstratos.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                    </SelectCustom>
                  </FormControl>
                </Grid>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  sx={{ textTransform: "none", height: "3em", marginTop: "1em" }}
                  startIcon={<AddBoxIcon />}
                  onClick={(e) => {
                    submitAction = "addToList";
                    handleSubmit();
                  }}
                >
                  Añadir a listado
                </Button>
              </Grid>
              <Grid item xs={8} sx={{ paddingRight: "3em" }}>
                {
                  estudiantes.length > 0 && (
                    <>
                      <Grid item sm={11} md={11} sx={{ pt: "0.5em", pb: "1em", pl: "3em" }}>
                        <Typography variant="h6" align="left" color="text.primary">
                          Listado de estudiantes a registrar/ Registros: {estudiantes.length}
                        </Typography>
                      </Grid>
                      <Grid item sm={12} md={12} >
                        <TableContainer component={Paper}>
                          <Table aria-label="customized table">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell width={"10%"}>Cod. Estudiante</StyledTableCell>
                                <StyledTableCell width={"10%"}>Colegio / Grado / Grupo</StyledTableCell>
                                <StyledTableCell width={"10%"}>Identificación</StyledTableCell>
                                <StyledTableCell width={"7%"}>Primer nombre</StyledTableCell>
                                <StyledTableCell width={"7%"}>Segundo nombre</StyledTableCell>
                                <StyledTableCell width={"7%"}>Primer apellido</StyledTableCell>
                                <StyledTableCell width={"7%"}>Segundo apellido</StyledTableCell>
                                <StyledTableCell width={"10%"}>Genero</StyledTableCell>
                                <StyledTableCell width={"10%"}>Fec. de Nacimiento</StyledTableCell>
                                <StyledTableCell width={"10%"}>Estrato</StyledTableCell>
                                {/* <StyledTableCell width={"10%"}>Acciones</StyledTableCell> */}
                                <StyledTableCell width={"10%"}>Resultado</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {estudiantes.map((item, index) => (
                                <StyledTableRow key={item.id}>
                                  <StyledTableCell component="th" scope="row">
                                    {item.cod_estudiante ?? ''}
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    {getColegioById(item.id_ie)} / {getGradoById(item.id_curso)} / {getGrupoById(item.id_grupo)}
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    {getTipoDocById(item.t_doc)} - {item.num_doc}
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    {item.pri_nom}
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    {item.seg_nom}
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    {item.pri_ape}
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    {item.seg_ape}
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    {getGeneroById(item.sexo)}
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    {item.fec_nac}
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    {getEstratoById(item.estrato)}
                                  </StyledTableCell>
                                  {/* <StyledTableCell component="th" scope="row">
                                                    <Button
                                                        variant="outlined"
                                                        size="small"
                                                        color="error"
                                                        startIcon={<DeleteIcon />}
                                                    // onClick={(e) => removeEstudiante(index)}
                                                    >
                                                        Remover
                                                    </Button>
                                                </StyledTableCell> */}
                                  <StyledTableCell component="th" scope="row">
                                    {item.status === true ? (
                                      <Tooltip title={item.mensaje} >
                                        <IconButton>
                                          <CheckBoxIcon
                                            fontSize="large"
                                            color="success" />
                                        </IconButton>
                                      </Tooltip>
                                    ) : (
                                      item.status === false ?
                                        <Tooltip title={item.mensaje}>
                                          <IconButton>
                                            <ErrorIcon
                                              fontSize="large"
                                              color="error" />
                                          </IconButton>
                                        </Tooltip>
                                        : <Tooltip title="No hay detalles de registro.">
                                          <IconButton>
                                            <HelpCenterIcon
                                              fontSize="large"
                                              color="disabled" />
                                          </IconButton>
                                        </Tooltip>
                                    )}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </>
                  )
                }
              </Grid>
            </Grid>
          </Box>
        )}
      </Formik>
    </>
  );
}

export default RegistroEstudianteForm;
