import React, { useState, useEffect } from "react";
import {
  Grid,
  FormHelperText,
  InputLabel,
  FormControl,
  Box,
  MenuItem,
  Button,
  TextField,
  Stack,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import { Formik } from "formik";
import { downloadExcel } from "react-export-table-to-excel";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import { DataGrid } from "@mui/x-data-grid";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import testService from "services/test.services";
import moment from "moment";
import * as Yup from "yup";
import SelectCustom from "components/ui/Selects/SelectCustom";
import TextFieldCustom from "components/ui/TextFields/TextFieldCustom";
import { columnsDGrid } from "utils/functions";
import {
  showAlertConfirm,
  showAlertError,
  showAlertInfo,
  showAlertInput,
  showAlertSuccess,
} from "utils/alert";

/*const columns = [
  {
    field: 'id',
    headerName: 'Evaluacion',
    //description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 100
  },
  {
    field: 'Codigo Estudiante',
    headerName: 'Estudiante',
    //description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 100
  },
  {
    field: 'Nombre 1',
    headerName: 'Nombre 1',
    //description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160
  },
  {
    field: 'Nombre 2',
    headerName: 'Nombre 2',
    //description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160
  },
  {
    field: 'Apellido 1',
    headerName: 'Apellido 1',
    //description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160
  },
  {
    field: 'Apellido 2',
    headerName: 'Apellido 2',
    //description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160
  },
  {
    field: 'Sexo',
    headerName: 'Sexo',
    //description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    hide: true
  },
  {
    field: 'Colegio',
    headerName: 'Colegio',
    //description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    hide: true
  },
  {
    field: 'Curso',
    headerName: 'Curso',
    //description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    hide: true
  },
  {
    field: 'Grupo',
    headerName: 'Grupo',
    //description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    hide: true
  },
  {
    field: 'Evaluador',
    headerName: 'Evaluador',
    //description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    hide: true
  },
  {
    field: 'Prueba',
    headerName: 'Prueba',
    //description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 200
  },
  {
    field: 'Estado',
    headerName: 'Estado',
    //description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 140
  },
  {
    field: 'Observación',
    headerName: 'Observación',
    //description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    hide: true
  },
  {
    field: 'Preguntas',
    headerName: 'Preguntas',
    //description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    hide: true
  },
  {
    field: 'Respondieron',
    headerName: 'Respondieron',
    //description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 100,
    hide: true
  },
  {
    field: 'Correctas',
    headerName: 'Correctas',
    //description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 100
  },
  {
    field: 'Incorrectas',
    headerName: 'Incorrectas',
    //description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 100
  },
  {
    field: 'Requiere Tiempo',
    headerName: 'Requiere Tiempo',
    //description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160
  },
  {
    field: 'Tiempo Segundos',
    headerName: 'Tiempo Segundos',
    //description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 100,
    hide: true
  },

];*/

/*const header = [  
  "id",
  "Codigo Estudiante",
  "Nombre 1",
  "Nombre 2",
  "Apellido 1",
  "Apellido 2",
  "Sexo",
  "Colegio",
  "Curso",
  "Grupo",
  "Evaluador",
  "Prueba",
  "Estado",
  "Observación",
  "Preguntas",
  "Respondieron",
  "Correctas",
  "Incorrectas",
  "Requiere Tiempo",
  "Tiempo Segundos"
];*/

const initialValues = {
  fechaInicio: moment(),
  fechaFin: moment(),
  colegio: "",
  grado: "",
  grupo: "",
  tipoPrueba: "",
  prueba: "",
  codEstudiante: "",
};

const formSchema = Yup.object().shape({
  fechaInicio: Yup.date().required("Campo Requerido"),
  fechaFin: Yup.date().required("Campo Requerido"),
  //codEstudiante: Yup.string().required("Campo Requerido"),
  tipoPrueba: Yup.string().required("Campo Requerido"),
  prueba: Yup.string().required("Campo Requerido"),
});

const ResultTest = ({ setOpenLoading }) => {
  const [colegios, setColegios] = useState([]);
  const [grados, setGrados] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [tipoPruebas, setTipoPruebas] = useState([]);
  const [pruebas, setPruebas] = useState([]);
  const [evaluaciones, setEvaluaciones] = useState([]);
  const [columns, setColumns] = useState([]);
  const [header, setHeader] = useState([]);
  const [filterCode, setFilterCode] = useState("filterCode");
  const [filterInactivas, setFilterInactivas] = useState("0");

  async function getColegios() {
    try {
      var response = await testService.resultTest_GetColegios();
      if (response.status === 200) {
        setColegios(response.data.data);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  async function getGrados(data) {
    try {
      var response = await testService.resultTest_GetGrados(data);
      if (response.status === 200) {
        setGrados(response.data.data);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  async function getGrupos(data) {
    try {
      var response = await testService.resultTest_GetGrupos(data);
      if (response.status === 200) {
        setGrupos(response.data.data);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  async function getTipoPruebas() {
    try {
      var response = await testService.resultTest_GetTipoPruebas();
      if (response.status === 200) {
        setTipoPruebas(response.data.data);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  async function getPruebas(tipoPrueba) {
    try {
      setPruebas(tipoPruebas.find((e) => e.key === tipoPrueba).pruebas);
      /*var response = await client('POST', {}, '/tests/' + tipoPrueba);
      if (response.status === 200) {
        setPruebas(response.data.data);
      }*/
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  async function getEvaluaciones(data) {
    try {
      setOpenLoading(true);
      var response = await testService.resultTest_GetEvaluaciones(data);
      if (response.status === 200) {
        setOpenLoading(false);
        if (response.data.data.length > 0) {
          setEvaluaciones(response.data.data);
        } else {
          showAlertInfo("No se encontraron datos");
        }
      }
    } catch (error) {
      setOpenLoading(false);
      showAlertError("No se encontraron datos");
    }
  }
  async function getEvaluacionesDetallado(data, resetForm) {
    try {
      setOpenLoading(true);
      var response = await testService.resultTest_GetEvaluacionesDetallado(
        data
      );
      if (response.status === 200) {
        setOpenLoading(false);
        if (response.data.data.resultados.length > 0) {
          let data = columnsDGrid(
            response.data.data.keys,
            response.data.data.titulos,
            response.data.data.resultados[0]
          );
          setEvaluaciones(response.data.data.resultados);
          if (filterInactivas === "1") {
            let jsonActions = {
              field: "Acciones",
              headerName: "Acciones",
              headerClassName: "super-app-theme--header",
              flex: 0.5,
              minWidth: 100,
              sortable: false,
              renderCell: (cellValues) => (
                <Button
                  type="button"
                  //fullWidth
                  variant="text"
                  sx={{ textTransform: "none", height: "3.5em" }}
                  onClick={() => {
                    showAlertConfirm(
                      "warning",
                      `¿Desea activar la evaluación # ${cellValues.row.id} ?`,
                      "La evaluación se cambiara a estado finalizado, no estará disponible para edición.",
                      async function (confirmed) {
                        if (confirmed) {
                          showAlertInput(
                            "Debe digitar la observación",
                            "¡Debe digitar la observación!",
                            async function (result) {
                              if (result.isConfirmed) {
                                let data = {
                                  evaluacion: cellValues.row.id,
                                  mensaje: result.value,
                                };
                                await activarEvaluacion(data).then(() => {
                                  clearForm();
                                  initForm();
                                  resetForm();
                                });
                              }
                            }
                          );
                        }
                      }
                    );
                  }}
                >
                  Activar
                </Button>
              ),
              disableClickEventBubbling: true,
            };
            data.unshift(jsonActions);
          }
          setColumns(data);
          setHeader(response.data.data.titulos);
        } else {
          showAlertInfo("No se encontraron datos");
        }
      }
    } catch (error) {
      setOpenLoading(false);
      showAlertError("No se encontraron datos");
    }
  }
  async function activarEvaluacion(data) {
    try {
      setOpenLoading(true);
      var response = await testService.activateTest(data);
      if (response.status === 200) {
        setOpenLoading(false);
        showAlertSuccess("Evaluación activada correctamente");
      }
    } catch (error) {
      setOpenLoading(false);
      showAlertError("Error al activar la prueba");
    }
  }

  let submitAction;

  const handleClickFilter = (event, resetForm) => {
    setFilterCode(event.target.value);
    clearForm();
    resetForm();
  };

  const hCFilterInactivas = (event, resetForm) => {
    setFilterInactivas(event.target.value);
  };
  function handleDownloadExcel() {
    downloadExcel({
      fileName: "Resultados estudiantes",
      sheet: "Resultados",
      tablePayload: {
        header,
        // accept two different data structures
        body: evaluaciones,
      },
    });
    //clearForm();
  }

  const initForm = () => {
    getColegios();
    getTipoPruebas();
  };

  const clearForm = () => {
    setColegios([]);
    setGrados([]);
    setGrupos([]);
    setTipoPruebas([]);
    setPruebas([]);
    setEvaluaciones([]);
    initForm();
  };

  useEffect(() => {
    initForm();
  }, []);
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={formSchema}
        validate={(values) => {
          const errors = {};
          if (
            moment(values.fechaInicio).valueOf() >
            moment(values.fechaFin).valueOf()
          ) {
            errors.fechaInicio = "No puede asignar";
            errors.fechaFin = "No puede asignar";
          }
          if (values.colegio) {
            let data = {
              colegio: values.colegio,
            };
            getGrados(data);
          }
          if (values.grado) {
            let data = {
              colegio: values.colegio,
              grado: values.grado,
            };
            getGrupos(data);
          }
          if (values.tipoPrueba) {
            getPruebas(values.tipoPrueba);
          }
          if (filterCode === "filterCode" && !values.codEstudiante) {
            errors.codEstudiante = "Campo Requerido";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (submitAction === "buscar") {
            var data = null;
            if (filterCode === "filterDate") {
              data = {
                tipo: 0,
                fechaInicio: moment(values.fechaInicio).format("YYYY-MM-DD"),
                fechaFin: moment(values.fechaFin).format("YYYY-MM-DD"),
                prueba: values.prueba,
              };
              if (values.grado) {
                data.grado = values.grado;
              }
              if (values.curso) {
                data.curso = values.curso;
              }
              if (values.colegio) {
                data.colegio = values.colegio;
              }
            } else {
              data = {
                tipo: 0,
                estudiante: values.codEstudiante,
                prueba: values.prueba,
              };
            }
            data.inactivas = parseInt(filterInactivas, 0);
            setEvaluaciones([]);
            getEvaluacionesDetallado(data, resetForm);
          } else {
            handleDownloadExcel();
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Box
            component="form"
            noValidate
            id="resutado-pruebas-form"
            sx={{ mt: 1 }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                bgcolor: "background.paper",
                borderRadius: 1,
              }}
            >
              {/* Radio */}
              <Grid item xs={12} sm={12} md={12} lg={3} sx={{ padding: "1em" }}>
                <FormControl>
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    Busqueda por...
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={filterCode}
                    onChange={(event) => handleClickFilter(event, resetForm)}
                  >
                    <FormControlLabel
                      value="filterCode"
                      control={<Radio />}
                      label="Código"
                    />
                    <FormControlLabel
                      value="filterDate"
                      control={<Radio />}
                      label="Fecha"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {/* Radio */}
              <Grid item xs={12} sm={12} md={12} lg={4} sx={{ padding: "1em" }}>
                <FormControl>
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    Estado
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={filterInactivas}
                    onChange={(event) => hCFilterInactivas(event, resetForm)}
                  >
                    <FormControlLabel
                      value={"0"}
                      control={<Radio />}
                      label="Activo"
                    />
                    <FormControlLabel
                      value={"1"}
                      control={<Radio />}
                      label="Inactivo"
                    />
                    <FormControlLabel
                      value={"2"}
                      control={<Radio />}
                      label="Cancelado"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {filterCode === "filterDate" ? (
                <>
                  {/* FechaInicio */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={2}
                    sx={{ padding: "1em" }}
                  >
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <Stack spacing={3}>
                        <DatePicker
                          onChange={(value) =>
                            setFieldValue("fechaInicio", value)
                          }
                          onBlur={(value) =>
                            setFieldValue("fechaInicio", value)
                          }
                          value={values.fechaInicio}
                          inputFormat="DD/MM/YYYY"
                          label="Fecha Inicio*"
                          renderInput={(params) => (
                            <TextField
                              label="Fecha Inicio"
                              name="FechaInicio"
                              error={
                                errors.fechaInicio && touched.fechaInicio
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.fechaInicio && touched.fechaInicio
                                  ? errors.fechaInicio
                                  : null
                              }
                              {...params}
                            />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>
                  {/* FechaFin */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={2}
                    sx={{ padding: "1em" }}
                  >
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <Stack spacing={3}>
                        <DatePicker
                          onChange={(value) => setFieldValue("fechaFin", value)}
                          onBlur={(value) => setFieldValue("fechaFin", value)}
                          value={values.fechaFin}
                          inputFormat="DD/MM/YYYY"
                          label="Fecha Fin*"
                          renderInput={(params) => (
                            <TextField
                              label="Fecha Fin"
                              name="fechaFin"
                              error={
                                errors.fechaFin && touched.fechaFin
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.fechaFin && touched.fechaFin
                                  ? errors.fechaFin
                                  : null
                              }
                              {...params}
                            />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>
                  {/* Colegios */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    sx={{ padding: "1em" }}
                  >
                    <FormControl
                      fullWidth
                      error={errors.colegio ? true : false}
                    >
                      <InputLabel id="input-label-colegio">Colegios</InputLabel>
                      <SelectCustom
                        labelId="select-label-colegio"
                        id="select-colegio"
                        name="colegio"
                        label="colegios"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.colegio}
                      >
                        <MenuItem value="">--</MenuItem>
                        {colegios &&
                          colegios.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.nombre}
                            </MenuItem>
                          ))}
                      </SelectCustom>
                      {errors.colegio && touched.colegio && (
                        <FormHelperText>{errors.colegio}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {/* Grados */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={2}
                    sx={{ padding: "1em" }}
                  >
                    <FormControl fullWidth error={errors.grado}>
                      <InputLabel id="input-label-grado">Grados</InputLabel>
                      <SelectCustom
                        labelId="select-label-grado"
                        id="select-grado"
                        name="grado"
                        label="grados"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.grado}
                      >
                        <MenuItem value="">--</MenuItem>
                        {grados &&
                          grados.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.nombre}
                            </MenuItem>
                          ))}
                      </SelectCustom>
                      {errors.grado && touched.grado && (
                        <FormHelperText>Campo Requerido</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {/* Grupos */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={2}
                    sx={{ padding: "1em" }}
                  >
                    <FormControl fullWidth error={errors.grupo}>
                      <InputLabel id="input-label-inst">Grupos</InputLabel>
                      <SelectCustom
                        labelId="select-label-grupo"
                        id="select-grupo"
                        name="grupo"
                        label="grupos"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.grupo}
                      >
                        <MenuItem value="">--</MenuItem>
                        {grupos &&
                          grupos.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.nombre}
                            </MenuItem>
                          ))}
                      </SelectCustom>
                      {errors.grupo && touched.grupo && (
                        <FormHelperText>Campo Requerido</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </>
              ) : (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  sx={{ padding: "1em" }}
                >
                  <TextFieldCustom
                    name="codEstudiante"
                    label="Código Estudiante"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={String(touched.codEstudiante)}
                    value={values.codEstudiante || ""}
                    error={errors.codEstudiante}
                  />
                </Grid>
              )}
              {/* Tipo Pruebas */}
              <Grid item xs={12} sm={12} md={6} lg={3} sx={{ padding: "1em" }}>
                <FormControl fullWidth error={errors.prueba ? true : false}>
                  <InputLabel id="input-label-inst">Tipos Pruebas*</InputLabel>
                  <SelectCustom
                    labelId="select-label-tipo-prueba"
                    id="select-tipo-prueba"
                    name="tipoPrueba"
                    label="tipos pruebas*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.tipoPrueba}
                  >
                    {tipoPruebas &&
                      tipoPruebas.map((item) => (
                        <MenuItem key={item.key} value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))}
                  </SelectCustom>
                  {errors.tipoPrueba && touched.tipoPrueba && (
                    <FormHelperText>Campo Requerido</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {/* Pruebas */}
              <Grid item xs={12} sm={12} md={6} lg={5} sx={{ padding: "1em" }}>
                <FormControl fullWidth error={errors.prueba ? true : false}>
                  <InputLabel id="input-label-inst">Pruebas*</InputLabel>
                  <SelectCustom
                    labelId="select-label-prueba"
                    id="select-prueba"
                    name="prueba"
                    label="pruebas*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.prueba}
                  >
                    {pruebas &&
                      pruebas.map((item) => (
                        <MenuItem key={item.key} value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))}
                  </SelectCustom>
                  {errors.prueba && touched.prueba && (
                    <FormHelperText>Campo Requerido</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {/* Buscar */}
              <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  sx={{ textTransform: "none", height: "3.5em" }}
                  startIcon={<SearchTwoToneIcon />}
                  onClick={() => {
                    submitAction = "buscar";
                    handleSubmit();
                  }}
                >
                  Buscar
                </Button>
              </Grid>
              {/* Evaluaciones */}
              {evaluaciones.length > 0 && (
                <>
                  <Grid item sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
                    <Button
                      type="button"
                      fullWidth
                      variant="outlined"
                      sx={{ textTransform: "none", height: "3.5em" }}
                      startIcon={<SystemUpdateAltIcon />}
                      onClick={() => {
                        submitAction = "habilitar";
                        handleSubmit();
                      }}
                    >
                      Descargar
                    </Button>
                  </Grid>
                  <div style={{ height: 420, width: "100%", padding: "1em" }}>
                    <DataGrid rows={evaluaciones} columns={columns} />
                  </div>
                </>
              )}
            </Grid>
          </Box>
        )}
      </Formik>
    </>
  );
};

export default ResultTest;
