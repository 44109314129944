import React, { useState } from "react";
import { Paper, Grid, Typography } from "@mui/material";
import HeaderPage from "components/HeaderPage";
import Loading from "components/Loading";
import Title from "components/Title";
import TestListForm from "../components/TestListForm";
import cache from "data/cache";

const TestList = () => {
  const [openLoading, setOpenLoading] = useState(false);
  const [currentUser /*, setCurrentUser*/] = useState(
    cache.getCacheJSON("appUser")
  );
  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          pt: "4em",
        }}
      >
        <Loading openLoading={openLoading} />
        <Title title="Pruebas" />
        <Grid item xs={12}>
          <HeaderPage
            descripcion={
              "Bienvenido, " +
              (!currentUser.user.nombre_1
                ? ""
                : currentUser.user.nombre_1 + " ") +
              (!currentUser.user.nombre_2
                ? ""
                : currentUser.user.nombre_2 + " ") +
              (!currentUser.user.apellido_1
                ? ""
                : currentUser.user.apellido_1 + " ") +
              (!currentUser.user.apellido_2
                ? ""
                : currentUser.user.apellido_2 + " ")
            }
            pt={"1em"}
          />
        </Grid>
        <Grid item xs={12} sx={{ pl: "1em" }}>
          <Typography
            variant="h6"
            component="h5"
            sx={{ pl: "10px" }}
          >
            Estas son las pruebas a realizar
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper
            elevation={0}
            sx={{
              margin: "1em",
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "flex-start",
                padding: "1em",
              }}
            >
              <TestListForm setOpenLoading={setOpenLoading} />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default TestList;
