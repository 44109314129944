import React, { useState } from "react";
import { TextField, Box, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Formik } from "formik";
import * as Yup from "yup";
import { showAlert } from "utils/alert";
import { useNavigate } from "react-router-dom";
import ButtonCustom from "components/ui/Buttons/ButtonCustom";
import Copyright from "components/Copyright";
import useLogin from "./useLogin";

const initialValues = {
  username: "",
  password: "",
};

const formSchema = Yup.object().shape({
  username: Yup.string()
    .required("Campo Requerido")
    .min(5, `Mínimo 5 caracteres`)
    .max(30, `Máximo 30 caracteres`),
  password: Yup.string().required("Campo Requerido"),
});

const LoginForm = ({ setOpenLoading }) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const { getSession } = useLogin();
  const navigate = useNavigate();
  return (
    <>
      <Formik
        initialValues={{ initialValues }}
        validationSchema={formSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setOpenLoading(true);
          getSession(values)
            .then(() => {
              setOpenLoading(false);
              navigate("/dashboard");
            })
            .catch((error) => {
              console.log(error);
              setOpenLoading(false);
              showAlert(
                "error",
                "!Ha ocurrido un error!",
                "Usuario y/o contraseña inválido"
              );
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
          /* and other goodies */
        }) => (
          <Box
            component="form-Login"
            noValidate
            /*onSubmit={handleSubmit}*/ sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              //required
              fullWidth
              id="username"
              label="Tu usuario"
              name="username"
              autoComplete="usuario"
              type={"username"}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.username || ""}
              autoFocus
              error={errors.username && touched.username ? true : false}
              helperText={
                errors.username && touched.username ? errors.username : null
              }
            />
            <TextField
              margin="normal"
              //required
              fullWidth
              name="password"
              label="Tu contraseña"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              onChange={handleChange("password")}
              onBlur={handleBlur}
              value={values.password || ""}
              error={errors.password && touched.password ? true : false}
              helperText={
                errors.password && touched.password ? errors.password : null
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      //onBlur={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <ButtonCustom
              type="button"
              fullWidth
              onClick={handleSubmit}
              sx={{ mt: 2, mb: 2 }}
            >
              Ingresar
            </ButtonCustom>
            <Copyright />
          </Box>
        )}
      </Formik>
    </>
  );
};

export default LoginForm;
