import React, { useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  tableCellClasses,
  Checkbox,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const TableChecked = (props) => {
  const { name, data, setData, id, headers, keys } = props;
  const [checkedAll, setCheckedAll] = useState(false);
  const hcCheckMain = (e) => {
    let tempList = data;
    let checked = e.target.checked;
    tempList.map((item) => (item.selected = checked));
    setData(tempList);
    setCheckedAll(checked);
  };
  const handleClickRow = (e, item) => {
    let array = data.map((row) => {
      if (row[id] === item[id]) row.selected = e.target.checked;
      return row;
    });
    let totalItems = data.length;
    let totalCheckedItems = array.filter((e) => e.selected).length;
    setData(array);
    setCheckedAll(totalItems === totalCheckedItems);
  };
  return (
    <>
      {/* data */}
      {data.length > 0 && (
        <>
          <Grid item sm={12} md={12} sx={{ pt: "0.5em", pl: "3em" }}>
            <Typography variant="h6" align="left" color="text.primary">
              {name} / Registros: {data.length}
            </Typography>
          </Grid>
          <Grid item sm={12} md={12} sx={{ padding: "2em" }}>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell width={"5%"} sx={{ pl: "15px" }}>
                      <Checkbox
                        color="secondary"
                        checked={checkedAll}
                        onClick={(e) => hcCheckMain(e)}
                      />
                    </StyledTableCell>
                    {headers.map((item2, index) => (
                      <StyledTableCell
                        width={item2.porcentaje}
                        key={item2.nombre}
                      >
                        {item2.nombre}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((item, index) => (
                    <StyledTableRow key={item[id]}>
                      <StyledTableCell component="th" scope="row">
                        <Checkbox
                          color="secondary"
                          checked={item.selected}
                          onClick={(e) => handleClickRow(e, item)}
                        />
                      </StyledTableCell>
                      {keys.map((itemKey) => (
                        <StyledTableCell
                          component="th"
                          scope="row"
                          key={itemKey}
                        >
                          {item[itemKey]}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </>
      )}
    </>
  );
};

export default TableChecked;
