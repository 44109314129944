const setCache = (key, value) => {
  if (value) {
    localStorage.setItem(key, value);
  } else {
    localStorage.removeItem(key);
  }
};

const setCacheJSON = (key, value) => {
  if (value) {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    localStorage.removeItem(key);
  }
};

const getCache = (key) => {
  return localStorage.getItem(key);
};

const getCacheJSON = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

const clearCache = () => {
  localStorage.clear();
}

const cache = {
  setCache,
  setCacheJSON,
  getCache,
  getCacheJSON,
  clearCache
};

export default cache;
