import React from 'react';

const style = {
    padding: '3px',
    margin: '3px',
};

const Instruccion = (props) => {    
    return <div style={style} dangerouslySetInnerHTML={{ __html: props.instruccion }} />;
}
export default Instruccion