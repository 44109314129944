import React from "react";
import ButtonIcon from "./ui/Buttons/ButtonIcon";

const AudioText = (props) => {
  function handleClick(e) {
    const utterance = new SpeechSynthesisUtterance(props.text);
    utterance.lang = 'es-CO';
    speechSynthesis.speak(utterance);
  }
  return (
    <>
      {props.text && (
        <ButtonIcon
          sx={{ height: "30px" }}
          id="btnSpeech"
          variant="outlined"
          onClick={(e) => handleClick(e)}
          iconname="play_circle"
        />
      )}
    </>
  );
};

export default AudioText;
