import client from "libs/client";

const getTestType = async (data) => {
  let response = await client("POST", data, "/tests/type");
  return response;
};

const getTest = async (tipoPrueba) => {
  let response = await client("POST", {}, "/tests/" + tipoPrueba);
  return response;
};

const assingTest = async (data) => {
  let response = await client("POST", data, "/tests/test/assign");
  return response;
};

const getAssingmentsTest = async () => {
  let response = await client("POST", {}, "/tests");
  return response;
};

const validarRespuestaTest = async (data) => {
  let response = await client("POST", data, "/tests/test/validate");
  return response;
};

// NOTE Servicios Inactivar-Resolver Pruebas
const inactivarTest_GetEvaluaciones = async (data) => {
  let response = await client("POST", data, "/tests/status");
  return response;
};
const inactivarTest_InactivarPrueba = async (data) => {
  let response = await client("POST", data, "/tests/test/inactivates");
  return response;
};
const inactivarTest_ActualizarFechaNacimiento = async (data) => {
  let response = await client("POST", data, "/students/update/birthdate");
  return response;
};

// NOTE Servicios Resultados Pruebas
const resultTest_GetColegios = async () => {
  let response = await client("POST", {}, "/schools");
  return response;
};
const resultTest_GetGrados = async (data) => {
  let response = await client("POST", data, "/schools/grade");
  return response;
};
const resultTest_GetGrupos = async (data) => {
  let response = await client("POST", data, "/schools/classroom");
  return response;
};
const resultTest_GetTipoPruebas = async (data) => {
  let response = await client("POST", data, "/tests/type");
  return response;
};
const resultTest_GetEvaluaciones = async (data) => {
  let response = await client("POST", data, "/tests/results");
  return response;
};
const resultTest_GetEvaluacionesDetallado = async (data) => {
  let response = await client("POST", data, "/tests/results/single");
  return response;
};

// Report TEst
const reportTest_GetEvaluacionesDetallado = async (data) => {
  let response = await client("POST", data, "/tests/report/status");
  return response;
};

//SolveTest
const startTest = async (evaluacion, fecha) => {
  let response = await client(
    "POST",
    { evaluacion, tiempo: Date.now(), fecha },
    "/tests/test/start"
  );
  return response;
};

const endTest = async (evaluacion, resultados) => {
  let response = await client(
    "POST",
    {
      evaluacion,
      resultados,
      tiempo: Date.now(),
    },
    "/tests/test/end"
  );
  return response;
};

const cancelTest = async (evaluacion, mensaje) => {
  let response = await client(
    "POST",
    { evaluacion, mensaje },
    "/tests/test/cancel"
  );
  return response;
};

const getTestDetail = async (evaluacion) => {
  let response = await client("POST", { evaluacion }, "/tests/test");
  return response;
};

const assingTestHistory = async (data) => {
  let response = await client("POST", data, "/tests/test/history");
  return response;
};

const activateTest = async (data) => {
  let response = await client("POST", data, "/tests/test/activate");
  return response;
};

// Calificar pruebas
const calificar_GetPruebasEstudiantes = async (data) => {
  let response = await client(
    "POST",
    data,
    "/tests/results/student"
  );
  return response;
};

const calificar_GetPrueba = async (data) => {
  let response = await client(
    "POST",
    data,
    "/tests/results/eval"
  );
  return response;
};

//Recursos Pruebas
const recursos_GetRecursosByPrueba = async (data) => {
  let response = await client(
    "POST",
    data,
    "/tests/resource"
  );
  return response;
};

const validateFile = async (route) => {
  let response = await client(
    "HEAD",
    null,
    route,
    true
  );
  return response;
};

const testService = {
  getTestType,
  getTest,
  assingTest,
  getAssingmentsTest,
  validarRespuestaTest,
  // NOTE Servicios Inactivar-Resolver Pruebas
  inactivarTest_GetEvaluaciones,
  inactivarTest_InactivarPrueba,
  inactivarTest_ActualizarFechaNacimiento,
  // NOTE Servicios Resultados Pruebas
  resultTest_GetColegios,
  resultTest_GetGrados,
  resultTest_GetGrupos,
  resultTest_GetTipoPruebas,
  resultTest_GetEvaluaciones,
  resultTest_GetEvaluacionesDetallado,
  // NOTE Servicios ReportPruebas
  reportTest_GetEvaluacionesDetallado,
  // NOTE SolveTest
  startTest,
  endTest,
  cancelTest,
  getTestDetail,
  assingTestHistory,
  activateTest,
  // Calificar pruebas
  calificar_GetPruebasEstudiantes,
  calificar_GetPrueba,
  // Recursos
  recursos_GetRecursosByPrueba,
  validateFile
};

export default testService;
