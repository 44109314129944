import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import ButtonIcon from "components/ui/Buttons/ButtonIcon";
import TextFieldCustom from "components/ui/TextFields/TextFieldCustom";
import QuickSearch from "components/QuickSearch";
import { showAlertError, showAlertSuccess } from "utils/alert";
import { useParams, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";

const UserForm = ({ setOpenLoading }) => {
  const initialValues = {
    cuenta: "",
    passwd: "",
    rol: "",
    user: "",
    state: ""
  };
  const formSchema = Yup.object().shape({
    cuenta: Yup.string().required("Campo Requerido"),
    passwd: Yup.string().required("Campo Requerido"),
    rol: Yup.string().required("Campo Requerido"),
    user: Yup.string().required("Campo Requerido"),
    state: Yup.string().required("Campo Requerido"),
  });  
  const clearForm = () => {
    //setSavedData(null);
  };
  const init = () => {};
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Formik
        enableReinitialize={false}
        initialValues={initialValues}
        validationSchema={formSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setOpenLoading(true);
          /*saveDestination(isAddMode, values, id)
            .then(() => {
              setOpenLoading(false);
              showAlertSuccess("Destino grabado correctamente");
              resetForm();
              clearForm();
              if (!Boolean(isModal)) {
                navigate("/dashboard/destinations");
              } else {
                setData();
                handleClose();
              }
            })
            .catch((error) => {
              console.log(error);
              setOpenLoading(false);
              showAlertError("No se completar la transacción");
            });¨*/
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
          setFieldValue,
          isLoading,
          /* and other goodies */
        }) => (
          <Box component="form-Login" noValidate sx={{ mt: 1 }}>
            {/* Toolbar */}
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                bgcolor: "background.paper",
              }}
            >
              <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
                <ButtonIcon
                  id="btnSubmit"
                  fullWidth
                  iconname="cleaning_services"
                  description="LIMPIAR"
                  sx={{ mt: "1.5em" }}
                  color="primary"
                  onClick={() => {
                    resetForm();
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
                <ButtonIcon
                  id="btnSubmit"
                  fullWidth
                  iconname="save"
                  description="GUARDAR"
                  sx={{ mt: "1.5em" }}
                  color="success"
                  onClick={handleSubmit}
                />
              </Grid>
            </Grid>
            {/* Page*/}
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                borderRadius: 1,
              }}
            >
              {/* code */}
              <Grid item xs={12} sm={12} md={6} lg={4} sx={{ padding: "1em" }}>
                <TextFieldCustom
                  autoFocus={true}
                  name="cuenta"
                  label="Cuenta"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.cuenta}
                  value={values.cuenta || ""}
                  error={errors.cuenta}
                />
              </Grid>

              {/* name */}
              <Grid item xs={12} sm={12} md={6} lg={4} sx={{ padding: "1em" }}>
                <TextFieldCustom
                  name="passwd"
                  label="Contraseña"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.passwd}
                  value={values.passwd || ""}
                  error={errors.passwd}
                />
              </Grid>
              {/* state */}
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                sx={{ padding: "1em" }}
              >
                <QuickSearch
                  name="state"
                  label="Estado*"
                  keyItem="id"
                  valueItem="desc"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.state}
                  error={errors.state}
                  touched={touched.state}
                  isQuickSearch={false}
                  data={[
                    {
                      id: "A",
                      desc: "ACTIVO",
                    },
                    {
                      id: "I",
                      desc: "INACTIVO",
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </Formik>
    </>
  );
};

export default UserForm;
