import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from "@mui/material";
import ButtonIcon from "./ui/Buttons/ButtonIcon";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalParams = ({ mostrar, description, component }) => {
  const [mostrarRI, setMostrarRI] = useState(mostrar);
  const handleClickOpen = () => {
    setMostrarRI(true);
  };

  const handleClose = () => {
    setMostrarRI(false);
  };
  return (
    <>
      <ButtonIcon
        id="btnBackHeader"
        fullWidth
        variant="text"
        onClick={handleClickOpen}
        iconname="open_in_new"
      />
      <Dialog
        open={mostrarRI}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogContent></DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalParams;
