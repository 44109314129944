import client from "libs/client";

const updateDatosEstudiantes = async (data) => {
    let response = await client("POST", data, "/students/update/all");
    return response;
};
const createDatosEstudiantes = async (data) => {
    let response = await client("POST", data, "/students/create/all");
    return response;
};

const crearUsuarios = async () => {
    let response = await client("POST", {}, "/auth/create");
    return response;
};

const estudianteService = {
    updateDatosEstudiantes,
    createDatosEstudiantes,
    crearUsuarios
};

export default estudianteService;