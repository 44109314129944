import React, { useState, useEffect } from "react";
import {
  Grid,
  FormHelperText,
  InputLabel,
  FormControl,
  Select,
  Box,
  MenuItem,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  tableCellClasses,
  Checkbox,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import SaveIcon from "@mui/icons-material/Save";
import HeaderPage from "components/HeaderPage";
import Loading from "components/Loading";
import Title from "components/Title";
import { Formik } from "formik";
import surveyService from "services/survey.services";
import testService from "services/test.services";
import * as Yup from "yup";
import SelectCustom from "components/ui/Selects/SelectCustom";
import { concatenar } from "utils/functions";
import { showAlertError, showAlertInfo, showAlertSuccess } from "utils/alert";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const initialValues = {
  tipoParticipante: "",
  participante: "",
  colegio: "",
  grado: "",
  grupo: "",
  tipoEncuesta: "",
  encuesta: "",
};

const formSchema = Yup.object().shape({
  tipoParticipante: Yup.string().required("Campo Requerido"),
  participante: Yup.string().required("Campo Requerido"),
  colegio: Yup.string().required("Campo Requerido"),
  grado: Yup.string().required("Campo Requerido"),
  grupo: Yup.string().required("Campo Requerido"),
  tipoEncuesta: Yup.string().required("Campo Requerido"),
  encuesta: Yup.string().required("Campo Requerido"),
});

const SurveyForStudentForm = () => {
  const [tipoParticipantes, setTipoParticipantes] = useState([]);
  const [participantes, setParticipantes] = useState([]);
  const [colegios, setColegios] = useState([]);
  const [grados, setGrados] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [tipoEncuestas, setTipoEncuestas] = useState([]);
  const [encuestas, setEncuestas] = useState([]);
  const [estudiantes, setEstudiantes] = useState([]);
  const [estudiantesHabilitados, setEstudiantesHabilitados] = useState([]);
  const [estudiantesChecked, setEstudiantesChecked] = useState(false);
  const [openLoading, setOpenLoading] = useState("stop");
  const theme = useTheme();
  async function getTipoParticipantes() {
    try {
      var response = await surveyService.getTipoParticipantes();
      if (response.status === 200) {
        setTipoParticipantes(response.data.data);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  async function getParticipantes(data) {
    try {
      var response = await surveyService.getParticipantes(data);
      if (response.status === 200) {
        setParticipantes(response.data.data);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  async function getColegios() {
    try {
      var response = await testService.resultTest_GetColegios();
      if (response.status === 200) {
        setColegios(response.data.data);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  async function getGrados(data) {
    try {
      var response = await testService.resultTest_GetGrados(data);
      if (response.status === 200) {
        setGrados(response.data.data);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  async function getGrupos(data) {
    try {
      var response = await testService.resultTest_GetGrupos(data);
      if (response.status === 200) {
        setGrupos(response.data.data);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  async function getTipoEncuestas() {
    try {
      var response = await surveyService.getTipoEncuestas();
      if (response.status === 200) {
        setTipoEncuestas(response.data.data);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  async function getEncuestas(tipoEncuesta) {
    try {
      setEncuestas(tipoEncuestas.find((e) => e.key === tipoEncuesta).pruebas);
      /*var response = await client('POST', {}, '/tests/poll/type');
      if (response.status === 200) {
        //var pruebas = response.data.data.find(e => e.value === 'ENCUESTA ESTUDIANTE').pruebas;
        setPruebas(response.data.data);
      }*/
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  async function getEstudiantes(data) {
    try {
      setOpenLoading(true);
      var response = await surveyService.getEstudiantes(data);
      if (response.status === 200) {
        setOpenLoading(false);
        let data = response.data.data;
        setEstudiantes(data);
        setEstudiantesHabilitados(data.filter((e) => e.selected));
      }
    } catch (error) {
      setOpenLoading(false);
      showAlertError("No se encontraron estudiantes");
    }
  }
  async function asignarPrueba(data) {
    try {
      setOpenLoading(true);
      var response = await surveyService.asignarPrueba(data);
      if (response.status === 200) {
        setOpenLoading(false);
        showAlertSuccess("Evaluaciones asignadas correctamente");
        clearForm();
      }
    } catch (error) {
      setOpenLoading(false);
      showAlertError("No se pudieron asignar las pruebas!");
    }
  }
  const hcCheckMain = (e) => {
    let tempList = estudiantes;
    tempList.map((item) => (item.selected = e.target.checked));
    setEstudiantesHabilitados(estudiantes.filter((e) => e.selected));
    setEstudiantes(tempList);
    setEstudiantesChecked(e.target.checked);
  };
  const hcEstudiantes = (e, item) => {
    let tempList = estudiantes;
    tempList.map((est) => {
      if (est.id === item.id) {
        est.selected = e.target.checked;
      }
      return est;
    });
    const totalItems = estudiantes.length;
    const totalCheckedItems = tempList.filter((e) => e.selected).length;
    setEstudiantesHabilitados(estudiantes.filter((e) => e.selected));
    setEstudiantes(tempList);
    setEstudiantesChecked(totalItems === totalCheckedItems);
  };
  let submitAction;
  const initForm = () => {
    getTipoParticipantes();
    getColegios();
    getTipoEncuestas();
  };

  const clearForm = () => {
    setColegios([]);
    setGrados([]);
    setGrupos([]);
    getTipoEncuestas([]);
    setEstudiantes([]);
    setEstudiantesHabilitados([]);
    setEstudiantesChecked(false);
    initForm();
  };

  useEffect(() => {
    initForm();
  }, []);
  return (
    <>
      <Loading openLoading={openLoading} />
      <Formik
        initialValues={initialValues}
        validationSchema={formSchema}
        validate={(values) => {
          //const errors = {};
          if (values.tipoParticipante) {
            let data = {
              tipo: values.tipoParticipante,
            };
            getParticipantes(data);
          }
          if (values.colegio) {
            let data = {
              colegio: values.colegio,
            };
            getGrados(data);
          }
          if (values.grado) {
            let data = {
              colegio: values.colegio,
              grado: values.grado,
            };
            getGrupos(data);
          }
          if (values.tipoEncuesta) {
            getEncuestas(values.tipoEncuesta);
          }
          //console.log(errors.colegio);
          //return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          //Swal.showLoading(null);
          if (submitAction === "buscar") {
            getEstudiantes({
              colegio: values.colegio,
              grado: values.grado,
              curso: values.grupo,
            });
          } else {
            if (estudiantesHabilitados.length === 0) {
              showAlertInfo("Debe seleccionar los estudiantes!");
            } else {
              let data = [];
              estudiantesHabilitados.map((item) => data.push(item.id));
              asignarPrueba({
                prueba: values.encuesta,
                estudiantes: data,
                participante: values.participante,
              }).then(() => {
                resetForm();
              });
            }
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
          /* and other goodies */
        }) => (
          <Box
            component="form"
            noValidate
            id="asignar-encuesta-form"
            //sx={{ mt: 1 }}
          >
            {/* Toolbar */}
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                //background: theme.palette.secondary.main,
                background: theme.palette.secondary.light,
                padding: "0,5em",
                minHeight: "3em",
                //pt: "3em"
              }}
            >
              {/* Habilitar */}
              <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  sx={{ textTransform: "none", height: "3.5em" }}
                  startIcon={<SaveIcon />}
                  onClick={() => {
                    submitAction = "habilitar";
                    handleSubmit();
                  }}
                >
                  Habilitar
                </Button>
              </Grid>
              {/* Buscar */}
              <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  sx={{ textTransform: "none", height: "3.5em" }}
                  startIcon={<SearchTwoToneIcon />}
                  onClick={() => {
                    submitAction = "buscar";
                    handleSubmit();
                  }}
                >
                  Buscar
                </Button>
              </Grid>
            </Grid>
            {/* Form */}
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                bgcolor: "background.paper",
                borderRadius: 1,
              }}
            >
              {/* Tipo Participante */}
              <Grid item xs={12} sm={12} md={6} lg={3} sx={{ padding: "1em" }}>
                <FormControl
                  fullWidth
                  error={errors.tipoParticipante ? true : false}
                >
                  <InputLabel id="input-label-tipoParticipantes">
                    Tipo Participante*
                  </InputLabel>
                  <SelectCustom
                    labelId="select-label-tipoParticipantes"
                    id="select-tipoParticipantes"
                    name="tipoParticipante"
                    label="tipo participante*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.tipoParticipante}
                  >
                    {tipoParticipantes &&
                      tipoParticipantes.map((item) => (
                        <MenuItem key={item.key} value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))}
                  </SelectCustom>
                  {errors.tipoParticipante && touched.tipoParticipante && (
                    <FormHelperText>{errors.tipoParticipante}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {/* Participante */}
              <Grid item xs={12} sm={12} md={6} lg={3} sx={{ padding: "1em" }}>
                <FormControl
                  fullWidth
                  error={errors.participante ? true : false}
                >
                  <InputLabel id="input-label-participante">
                    Participante*
                  </InputLabel>
                  <SelectCustom
                    labelId="select-label-participante"
                    id="select-participante"
                    name="participante"
                    label="participante*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.participante}
                  >
                    {participantes &&
                      participantes.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {concatenar([
                            item.num_doc ? item.num_doc + " - " : "",
                            item.pri_nom,
                            item.seg_nom,
                            item.pri_ape,
                            item.seg_ape,
                          ])}
                        </MenuItem>
                      ))}
                  </SelectCustom>
                  {errors.participante && touched.participante && (
                    <FormHelperText>{errors.participante}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {/* Colegios */}
              <Grid item xs={12} sm={12} md={6} lg={6} sx={{ padding: "1em" }}>
                <FormControl fullWidth error={errors.colegio ? true : false}>
                  <InputLabel id="input-label-colegio">Colegios*</InputLabel>
                  <SelectCustom
                    labelId="select-label-colegio"
                    id="select-colegio"
                    name="colegio"
                    label="colegios*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.colegio}
                  >
                    {colegios &&
                      colegios.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.nombre}
                        </MenuItem>
                      ))}
                  </SelectCustom>
                  {errors.colegio && touched.colegio && (
                    <FormHelperText>{errors.colegio}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {/* Grados */}
              <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
                <FormControl fullWidth error={errors.grado ? true : false}>
                  <InputLabel id="input-label-grado">Grados*</InputLabel>
                  <SelectCustom
                    labelId="select-label-grado"
                    id="select-grado"
                    name="grado"
                    label="grados*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.grado}
                  >
                    {grados &&
                      grados.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.nombre}
                        </MenuItem>
                      ))}
                  </SelectCustom>
                  {errors.grado && touched.grado && (
                    <FormHelperText>{errors.grado}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {/* Grupos */}
              <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
                <FormControl fullWidth error={errors.grupo ? true : false}>
                  <InputLabel id="input-label-inst">Grupos*</InputLabel>
                  <SelectCustom
                    labelId="select-label-grupo"
                    id="select-grupo"
                    name="grupo"
                    label="grupos*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.grupo}
                  >
                    {grupos &&
                      grupos.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.nombre}
                        </MenuItem>
                      ))}
                  </SelectCustom>
                  {errors.grupo && touched.grupo && (
                    <FormHelperText>{errors.grupo}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {/* Tipo Pruebas */}
              {
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  sx={{ padding: "1em" }}
                >
                  <FormControl
                    fullWidth
                    error={errors.tipoEncuesta ? true : false}
                  >
                    <InputLabel id="input-label-encuesta">
                      Tipos Encuestas
                    </InputLabel>
                    <SelectCustom
                      labelId="select-label-encuesta"
                      id="select-tipo-encuesta"
                      name="tipoEncuesta"
                      label="tipos encuestas"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tipoEncuesta}
                    >
                      {tipoEncuestas &&
                        tipoEncuestas.map((item) => (
                          <MenuItem key={item.key} value={item.key}>
                            {item.value}
                          </MenuItem>
                        ))}
                    </SelectCustom>
                    {errors.tipoEncuesta && touched.tipoEncuesta && (
                      <FormHelperText>{errors.tipoEncuesta}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              }
              {/* Pruebas */}
              <Grid item xs={12} sm={12} md={6} lg={4} sx={{ padding: "1em" }}>
                <FormControl fullWidth error={errors.encuesta ? true : false}>
                  <InputLabel id="input-label-encuesta">Encuestas*</InputLabel>
                  <SelectCustom
                    labelId="select-label-encuesta"
                    id="select-encuesta"
                    name="encuesta"
                    label="encuestas*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.encuesta}
                  >
                    {encuestas &&
                      encuestas.map((item) => (
                        <MenuItem key={item.key} value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))}
                  </SelectCustom>
                  {errors.encuesta && touched.encuesta && (
                    <FormHelperText>{errors.encuesta}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {/* Estudiantes */}
              {estudiantes.length > 0 && (
                <>
                  <Grid item sm={12} md={12} sx={{ pt: "0.5em", pl: "3em" }}>
                    <Typography variant="h6" align="left" color="text.primary">
                      Listado de estudiantes / Registros: {estudiantes.length}
                    </Typography>
                  </Grid>
                  <Grid item sm={12} md={12} sx={{ padding: "1em" }}>
                    <TableContainer component={Paper}>
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell width={"5%"} sx={{ pl: "15px" }}>
                              {/* <Checkbox color="secondary" /> */}
                              <Checkbox
                                id="mainCheck"
                                color="secondary"
                                checked={estudiantesChecked}
                                onClick={(e) => hcCheckMain(e)}
                              />
                            </StyledTableCell>
                            <StyledTableCell width={"5%"}>#</StyledTableCell>
                            <StyledTableCell width={"10%"}>
                              Codigo
                            </StyledTableCell>
                            <StyledTableCell width={"20%"}>
                              Identificador
                            </StyledTableCell>
                            <StyledTableCell width={"10%"}>
                              Realizo Prueba
                            </StyledTableCell>
                            <StyledTableCell width={"60%"}>
                              Nombre
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {estudiantes &&
                            estudiantes.map((item, index) => (
                              <StyledTableRow key={item.id}>
                                <StyledTableCell component="th" scope="row">
                                  <Checkbox
                                    id={String(item.id)}
                                    color="secondary"
                                    checked={item.selected}
                                    onClick={(e) => hcEstudiantes(e, item)}
                                  />
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                  {index + 1}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                  {item.id}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                  {item.identificador}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                  {item.realizoEncuesta ? "Si" : "No"}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                  {item.nombre}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        )}
      </Formik>
    </>
  );
};

export default SurveyForStudentForm;
